<div *ngIf="vm$ | async as vm" class="pending-payments-container sf-unmask">
    <div class="delinquent" *ngIf="delinquentCount > 0">
        <p>{{delinquentLabel}} ({{delinquentTotal | currency}})</p>
    </div>
    <form [formGroup]="vm.pendingPaymentsDisplayForm">
        <div tabindex="-1" #orgSelectorRow class="form-row">
            <div *ngIf="!dialogMode && orgSelectorData.length > 1" class="input-group mb-2 mr-sm-3 col-md-3">
                <sf-select
                    formControlName="selectedOrgOption"
                    [options]="orgSelectorData"
                    [isSingleSelect]=true
                    [hasClearButton]="orgSelectorData.length > 1 && canClearSelectedOrg"
                    (select)="onOrgSelect()"
                    trackBy="option" labelBy="label"
                    rightMetadataKey="pendingPaymentCount"
                    filterPlaceholder="Filter..." [hasFilter]="orgSelectorData.length > 1"
                ></sf-select>
            </div>
            <div *ngIf="!dialogMode && orgSelectorData.length === 1" class="input-group mb-2 ml-1 mr-sm-3 col-md-3"
                    style="color: #0b64a3">
                <span>{{orgSelectorData[0].label}}</span>
            </div>
            <div *ngIf="!dialogMode" class="ml-auto">
                <span [ngbTooltip]="getManagePaymentAccountsTooltip()" placement="top" container="body">
                    <button class="btn btn-link sf-btn-link mb-2 btn-secondary" style="text-decoration: none"
                            [disabled]="!hasManageAccountPermission()" (click)="goToManageAccounts()">
                        <fa-icon class="manage-payments-icon" [icon]="['far', 'list-ul']"></fa-icon> Manage Payment Accounts &nbsp;
                    </button>
                </span>
            </div>
        </div>
        <div class="form-row">
            <div class="btn-group btn-group-toggle mb-2" ngbRadioGroup name="radioBasic"
                    formControlName="selectedPaymentType">
                <label *ngIf="showEcheckSelector" ngbButtonLabel
                        class="btn-secondary toggle-report-category echeck-tour-step-2 paymentCountLabel">
                    <input type="radio" ngbButton value="ECHECK">eCheck
                    <div *ngIf="pendingPaymentCountsLoaded && selectedOrgEcheckCount > 0" class="paymentCountDiv">
                        <div class="badge {{selectedOrgEcheckCountClass}} paymentBadgeDiv">
                            <div class="paymentCount">{{selectedOrgEcheckCount}}</div>
                        </div>
                    </div>
                    <span *ngIf="!pendingPaymentCountsLoaded">
                    &nbsp;  <fa-icon icon="spinner" [spin]="true"></fa-icon>
                    </span>
                </label>
                <label *ngIf="showCreditCardSelector" ngbButtonLabel
                        class="btn-secondary toggle-report-category paymentCountLabel">
                    <input type="radio" ngbButton value="CREDIT_CARD">Credit Card
                    <div *ngIf="pendingPaymentCountsLoaded && selectedOrgCreditCardCount > 0" class="paymentCountDiv">
                        <div class="badge {{selectedOrgCreditCardClass}} paymentBadgeDiv">
                            <div class="paymentCount">{{selectedOrgCreditCardCount}}</div>
                        </div>
                    </div>
                    <span *ngIf="!pendingPaymentCountsLoaded">
                    &nbsp;  <fa-icon icon="spinner" [spin]="true"></fa-icon>
                    </span>
                </label>
                <label *ngIf="showACHSelector" ngbButtonLabel
                        class="btn-secondary toggle-report-category paymentCountLabel">
                    <input type="radio" ngbButton value="ACH">ACH
                </label>
            </div>
            <div *ngIf="selectedPaymentType === 'ECHECK'" class="form-check ml-4 col-md-12 col-lg-12 col-xl-2 my-2" style="top: 4px;">
                <input class="form-check-input" type="checkbox" formControlName="hideApprovedPayments" aria-label="Hide Approved Payments">
                <label class="form-check-label">  Hide Approved Payments</label>
            </div>
            <div *ngIf="selectedPaymentType && selectedPaymentType !== 'ACH' && !dialogMode" class="ml-auto">
                <span [ngbTooltip]="getApprovedTooltip()" placement="left" container="body">
                    <button class="btn btn-primary ml-2 mb-2"
                            [disabled]="!this.hasSelectedRows || !this.selectedPaymentsValid"
                            (click)="submitSelectedPendingPayments()">
                        {{selectedPaymentType === 'ECHECK' ? 'Approve Selected' : 'Retry Selected'}}
                    </button>
                </span>
            </div>
        </div>
    </form>

    <div tabindex="-1" #pendingPaymentGrid class="pending-payments-grid-container" *ngIf="pendingPayments$ | async as pendingPayments">
        <div *ngIf="!pendingPaymentsLoaded">
            <fa-icon icon="spinner" [spin]="true"></fa-icon> Loading...
        </div>

        <ag-grid-angular *ngIf="pendingPaymentsLoaded"
                         class="ag-grid ag-theme-sf"
                         [ngClass]="setVerticalScroll()"
                         suppressRowClickSelection
                         suppressCellFocus
                         suppressMovableColumns
                         rowSelection="multiple"
                         [rowHeight]="45"
                         [rowData]="pendingPayments"
                         [animateRows]="true"
                         (gridReady)="onGridReady($event)"
                         (gridSizeChanged)="onGridSizeChanged()"
                         [defaultColDef]="defaultColumnDef"
                         [autoGroupColumnDef]="autoGroupColumnDef"
                         [getDataPath]="getDataPath"
                         [treeData]="true"
                         [getRowId]="getRowId"
                         (selectionChanged)="getSelectedRowData()"
                         [isRowSelectable]="isRowSelectable"
                         [components]="frameworkComponents"
                         [loadingOverlayComponent]="loadingOverlayComponent"
                         [loadingOverlayComponentParams]="loadingOverlayComponentParams"
                         [noRowsOverlayComponent]="noRowsOverlayComponent"
                         [noRowsOverlayComponentParams]="noRowsOverlayComponentParams"
                         [enableCellTextSelection]="true"
                         [ensureDomOrder]="true"
                         [getRowClass]="getRowClass"
                         (firstDataRendered)="onFirstData($event)"
        >
        </ag-grid-angular>
    </div>
</div>

<ng-template #itemNameTemplate let-pendingPayment>
    <div class="item-name-indent" id="item-{{getQAATestingID(pendingPayment)}}">
        <span *ngIf="(pendingPayment.pendingPaymentMethodType === 'ACH' && pendingPayment.items.length > 1) || pendingPayment.label === 'Other' || pendingPayment.label === 'Document Builder Fees' || pendingPayment.label === 'Reprocess' || pendingPayment.pendingPaymentType === 'RETURN_FEE'">{{pendingPayment.label}}</span>
        <span *ngIf="!hasSubmitterPackageViewerPermission(pendingPayment.organizationID) && ((pendingPayment.pendingPaymentMethodType === 'CREDIT_CARD' && pendingPayment.pendingPaymentType !== 'LICENSE') || (pendingPayment.pendingPaymentMethodType === 'ECHECK' && pendingPayment.echeck.type !== 'LICENSE') || (pendingPayment.pendingPaymentMethodType === 'ACH' && pendingPayment.items.length === 1) || pendingPayment.isChild) && (pendingPayment.pendingPaymentType !== 'LICENSE' && pendingPayment.label !== 'Other' && pendingPayment.label !== 'Document Builder Fees' && pendingPayment.label !== 'Reprocess')">{{pendingPayment.label}}</span>
        <button class="btn btn-link sf-btn-link text-select"
                *ngIf="hasSubmitterPackageViewerPermission(pendingPayment.organizationID) && ((pendingPayment.pendingPaymentMethodType === 'CREDIT_CARD' && pendingPayment.pendingPaymentType !== 'LICENSE') || (pendingPayment.pendingPaymentMethodType === 'ECHECK' && pendingPayment.echeck.type === 'RECORDING') || (pendingPayment.pendingPaymentMethodType === 'ACH' && pendingPayment.items.length === 1 && pendingPayment.packageID) || pendingPayment.isChild) && (pendingPayment.pendingPaymentType !== 'LICENSE' && pendingPayment.label !== 'Other' && pendingPayment.label !== 'Document Builder Fees' && pendingPayment.label !== 'Reprocess' && pendingPayment.label !== 'NSF')"
                style="text-decoration: none" ngbTooltip="Click to view details for package {{pendingPayment.label}}"
                placement="left" container="body" (click)="paymentItemClicked(pendingPayment.packageID)"
                (mousedown)="handleMiddleClick($event, pendingPayment.packageID)">
            <span *ngIf="pendingPayment.isRefund">REFUND - </span>{{pendingPayment.label}}
        </button>
        <button class="btn btn-link sf-btn-link text-select" *ngIf="pendingPayment.pendingPaymentType === 'LICENSE'"
                style="text-decoration: none" [ngbTooltip]="getLicenseFeeTooltip(pendingPayment.organizationID)"
                placement="right" container="body"
                (click)="licenseFeeClicked(pendingPayment.organizationID, pendingPayment.label)"
                (mousedown)="handleLicenseFeeMiddleClick($event, pendingPayment.organizationID, pendingPayment.label)">
            {{pendingPayment.label}}
        </button>
        <!--        Need to handle CC License and license renewals-->
    </div>
</ng-template>

<!--<ng-template #methodTemplate let-pendingPayment>-->
<!--    <div *ngIf="pendingPayment.isChild || pendingPayment.items.length === 1">{{pendingPayment.paymentMethod}}</div>-->
<!--</ng-template>-->

<ng-template #orgTemplate let-pendingPayment>
    <div id="organization-{{getQAATestingID(pendingPayment)}}">{{getOrganizationName(pendingPayment.organizationID)}}</div>
</ng-template>

<ng-template #paymentAccountTemplate let-pendingPayment>
    <div *ngIf="showPaymentAccountSelector(pendingPayment)" [formGroup]="transactionsForm"
        [id]="'payment-account-' + getQAATestingID(pendingPayment)" class="select-margin sf-mask">
        <ng-container [formGroupName]="getPaymentAccountGroupName(pendingPayment)">
            <sf-select
                style="line-height: 20px"
                [formControlName]="getPaymentAccountFormControlName(pendingPayment)"
                [placeholder]="getPaymentAccountPlaceholder(pendingPayment)"
                [options]="getPaymentAccountSelectorAccounts(pendingPayment)"
                [isSingleSelect]=true
                [required]="true"
                trackBy="id" labelBy="label"
                [groupBy]="pendingPayment.isRefund ? '' : 'group'"
                [ngbTooltip]="getPaymentAccountTooltip(pendingPayment)"
                (select)="onSelect()"
                placement="left" container="body"
                [withFooter]="!pendingPayment.isRefund && pendingPayment.ccAllowed"
                [footerActionLeftLabel]="pendingPayment.pendingPaymentType === 'RECORDING' ? '  Add New Credit Card' :'  Add New Credit Card and Retry Payment'"
                [footerActionLeftIcon]="['far', 'credit-card']"
                (footerActionLeft)="addNewCreditCard(pendingPayment.organizationID, pendingPayment.achReferenceID, pendingPayment.pendingPaymentType === 'RECORDING')"
            ></sf-select>
        </ng-container>
    </div>

    <div *ngIf="!showPaymentAccountSelector(pendingPayment)" [id]="'payment-account-' + getQAATestingID(pendingPayment)"
         [ngbTooltip]="getPaymentAccountTooltip(pendingPayment)" placement="left"
         container="body" class="sf-mask">
        <span>{{pendingPayment.paymentAccountLabel}}</span>
    </div>

<!--    <div *ngIf="pendingPayment.isRefund && pendingPayment.pendingPaymentMethodType === 'ECHECK' && !pendingPayment.inDialogMode"-->
<!--            [formGroup]="transactionsForm" class="select-margin sf-mask"-->
<!--            id="payment-account-{{getQAATestingID(pendingPayment)}}">-->
<!--        <ng-container formGroupName="{{pendingPayment.echeck.echeckID}}">-->
<!--            <sf-select-->
<!--                    style="line-height: 20px"-->
<!--                    formControlName="targetAccountID"-->
<!--                    placeholder="Send Refund to..."-->
<!--                    [options]="pendingPayment.refundAccounts"-->
<!--                    [isSingleSelect]=true-->
<!--                    [isDisabled]="pendingPayment.packageArchived"-->
<!--                    [required]="true"-->
<!--                    trackBy="id" labelBy="label"-->
<!--                    [ngbTooltip]="getPaymentAccountTooltip(pendingPayment.organizationID, pendingPayment.packageArchived)"-->
<!--                    (select)="onSelect()"-->
<!--                    placement="left" container="body"-->
<!--            ></sf-select>-->
<!--        </ng-container>-->
<!--    </div>-->
<!--    <ng-container>-->
<!--&lt;!&ndash;        ACH&ndash;&gt;-->
<!--        <div id="payment-account-{{getQAATestingID(pendingPayment)}}" *ngIf="!pendingPayment.isRefund && (pendingPayment.isChild && (pendingPayment.pendingPaymentType === 'RECORDING' || (pendingPayment.pendingPaymentType === 'LICENSE' && pendingPayment.contractID)) && !pendingPayment.inDialogMode ||-->
<!--                        (pendingPayment.pendingPaymentMethodType === 'ACH' && pendingPayment.items.length === 1 && (pendingPayment.pendingPaymentType === 'RECORDING' || (pendingPayment.pendingPaymentType === 'LICENSE' && pendingPayment.contractID)))) && !pendingPayment.inDialogMode"-->
<!--                [formGroup]="transactionsForm" class="select-margin sf-mask">-->
<!--        <ng-container formGroupName="{{pendingPayment.achReferenceID}}">-->
<!--                <sf-select-->
<!--                        style="line-height: 20px"-->
<!--                        formControlName="paymentAccountID"-->
<!--                        aria-label="Payment Accounts"-->
<!--                        [options]="this.filterAccounts(pendingPayment)"-->
<!--                        [isSingleSelect]="true"-->
<!--                        [isDisabled]="pendingPayment.packageArchived"-->
<!--                        trackBy="id" labelBy="label" groupBy="group"-->
<!--                        [ngbTooltip]="getPaymentAccountTooltip(pendingPayment.organizationID, pendingPayment.packageArchived)"-->
<!--                        (select)="onSelect()"-->
<!--                        placement="left" container="body"-->
<!--                        [withFooter]="pendingPayment.ccAllowed"-->
<!--                        [footerActionLeftLabel]="pendingPayment.pendingPaymentType === 'RECORDING' ? '  Add New Credit Card' :'  Add New Credit Card and Retry Payment'"-->
<!--                        [footerActionLeftIcon]="['far', 'credit-card']"-->
<!--                        (footerActionLeft)="addNewCreditCard(pendingPayment.organizationID, pendingPayment.achReferenceID, pendingPayment.pendingPaymentType === 'RECORDING')"-->
<!--                ></sf-select>-->
<!--            </ng-container>-->
<!--        </div>-->
<!--&lt;!&ndash;        Credit Card&ndash;&gt;-->
<!--        <div id="payment-account-{{getQAATestingID(pendingPayment)}}"-->
<!--                *ngIf="pendingPayment.pendingPaymentMethodType === 'CREDIT_CARD' && !pendingPayment.inDialogMode && (pendingPayment.pendingPaymentType === 'RECORDING' || (pendingPayment.pendingPaymentType === 'LICENSE' && pendingPayment.contractID) || pendingPayment.pendingPaymentType === 'OTHER' || pendingPayment.pendingPaymentType === 'RETRY')"-->
<!--                [formGroup]="transactionsForm" class="select-margin credit-card-tour-step-2 sf-mask">-->
<!--        <ng-container formGroupName="{{pendingPayment.failedTransactionItemID}}">-->
<!--                <sf-select-->
<!--                        style="line-height: 20px"-->
<!--                        formControlName="paymentAccountID"-->
<!--                        [options]="this.filterAccounts(pendingPayment)"-->
<!--                        [isSingleSelect]="true"-->
<!--                        [isDisabled]="pendingPayment.packageArchived || pendingPayment.pendingPaymentType === 'OTHER' || pendingPayment.pendingPaymentType === 'RETRY'"-->
<!--                        trackBy="id" labelBy="label" groupBy="group"-->
<!--                        [ngbTooltip]="getPaymentAccountTooltip(pendingPayment.organizationID, pendingPayment.packageArchived)"-->
<!--                        (select)="onSelect()"-->
<!--                        placement="left" container="body"-->
<!--                        [withFooter]="pendingPayment.ccAllowed"-->
<!--                        [footerActionLeftLabel]="pendingPayment.pendingPaymentType === 'RECORDING' ? '  Add New Credit Card' :'  Add New Credit Card and Retry Payment'"-->
<!--                        [footerActionLeftIcon]="['far', 'credit-card']"-->
<!--                        (footerActionLeft)="addNewCreditCard(pendingPayment.organizationID, pendingPayment.failedTransactionItemID, pendingPayment.pendingPaymentType === 'RECORDING')"-->
<!--                ></sf-select>-->
<!--            </ng-container>-->
<!--        </div>-->
<!--&lt;!&ndash;        Echeck&ndash;&gt;-->
<!--        <div id="payment-account-{{getQAATestingID(pendingPayment)}}"-->
<!--                *ngIf="!pendingPayment.isRefund && pendingPayment.pendingPaymentMethodType === 'ECHECK' && !pendingPayment.approved && (pendingPayment.pendingPaymentType === 'RECORDING' || (pendingPayment.pendingPaymentType === 'LICENSE' && pendingPayment.contractID)) && !pendingPayment.inDialogMode"-->
<!--             [formGroup]="transactionsForm" class="select-margin sf-mask">-->
<!--            <ng-container formGroupName="{{pendingPayment.echeck.echeckID}}">-->
<!--                <sf-select-->
<!--                        style="line-height: 20px"-->
<!--                        formControlName="paymentAccountID"-->
<!--                        [options]="this.filterAccounts(pendingPayment)"-->
<!--                        [isSingleSelect]="true"-->
<!--                        [isDisabled]="pendingPayment.packageArchived"-->
<!--                        trackBy="id" labelBy="label" groupBy="group"-->
<!--                        [ngbTooltip]="getPaymentAccountTooltip(pendingPayment.organizationID, pendingPayment.packageArchived)"-->
<!--                        (select)="onSelect()"-->
<!--                        placement="left" container="body"-->
<!--                        [withFooter]="pendingPayment.ccAllowed"-->
<!--                        [footerActionLeftLabel]="pendingPayment.pendingPaymentType === 'RECORDING' ? '  Add New Credit Card' :'  Add New Credit Card and Retry Payment'"-->
<!--                        [footerActionLeftIcon]="['far', 'credit-card']"-->
<!--                        (footerActionLeft)="addNewCreditCard(pendingPayment.organizationID, pendingPayment.echeck.echeckID, pendingPayment.pendingPaymentType === 'RECORDING')"-->
<!--                ></sf-select>-->
<!--            </ng-container>-->
<!--        </div>-->
<!--&lt;!&ndash;        Non Selectable payments. Eventually remove pendingPayment.isChild&ndash;&gt;-->
<!--        <div id="payment-account-{{getQAATestingID(pendingPayment)}}" *ngIf="pendingPayment.inDialogMode ||-->
<!--                    (!pendingPayment.isRefund && pendingPayment.pendingPaymentMethodType === 'ECHECK' && pendingPayment.approved) ||-->
<!--                    (!pendingPayment.isRefund && pendingPayment.pendingPaymentMethodType === 'ECHECK' && pendingPayment.pendingPaymentType === 'RETRY') ||-->
<!--                    (!pendingPayment.isRefund && pendingPayment.pendingPaymentMethodType === 'ECHECK' && ((pendingPayment.pendingPaymentType === 'LICENSE' && !pendingPayment.contractID) || pendingPayment.pendingPaymentType === 'DOCUMENT_BUILDER' || pendingPayment.pendingPaymentType === 'OTHER')) ||-->
<!--                    (pendingPayment.pendingPaymentMethodType === 'CREDIT_CARD' && (pendingPayment.pendingPaymentType === 'LICENSE' && !pendingPayment.contractID)) ||-->
<!--                    (pendingPayment.pendingPaymentMethodType === 'ACH' && pendingPayment.items.length > 1) ||-->
<!--                    (pendingPayment.pendingPaymentMethodType === 'ACH' && pendingPayment.isRefund) ||-->
<!--                    (pendingPayment.isRefund && pendingPayment.isChild) ||-->
<!--                     (pendingPayment.pendingPaymentMethodType === 'ACH' && pendingPayment.items.length === 1 && ((pendingPayment.pendingPaymentType === 'LICENSE' && !pendingPayment.contractID) || pendingPayment.pendingPaymentType === 'DOCUMENT_BUILDER' || pendingPayment.pendingPaymentType === 'OTHER')) ||-->
<!--                     (pendingPayment.isChild && ((pendingPayment.pendingPaymentType === 'LICENSE' && !pendingPayment.contractID) || pendingPayment.pendingPaymentType === 'DOCUMENT_BUILDER' || pendingPayment.pendingPaymentType === 'OTHER'))"-->
<!--                [ngbTooltip]="getPaymentAccountTooltip(pendingPayment.organizationID, pendingPayment.packageArchived)" placement="left"-->
<!--                container="body" class="sf-mask">{{pendingPayment.paymentAccountLabel}}</div>-->
<!--    </ng-container>-->
</ng-template>

<ng-template #dateTemplate let-pendingPayment>
    <div id="date-{{getQAATestingID(pendingPayment)}}">{{pendingPayment.referenceDate | date: 'M/d/yyyy'}}</div>
</ng-template>

<ng-template #totalAmountTemplate let-pendingPayment>
    <div id="amount-{{getQAATestingID(pendingPayment)}}"
            *ngIf="pendingPayment.inDialogMode || !pendingPayment.packageID">{{formatAmount(pendingPayment.feeTotals.total)}}</div>
    <button id="amount-{{getQAATestingID(pendingPayment)}}"
            *ngIf="pendingPayment.packageID && !pendingPayment.inDialogMode"
            class="btn btn-link sf-btn-link text-select" style="text-decoration: none"
            ngbTooltip="Click to view fee details" placement="right" container="body"
            (click)="clickFeeDetails(pendingPayment)"
            (mousedown)="handleMiddleClick($event, pendingPayment.packageID)">{{formatAmount(pendingPayment.feeTotals.total)}}</button>
</ng-template>

<ng-template #checkNumberTemplate let-pendingPayment>
    <!--    only show if echeck and not refund -->
    <div id="check-number-{{getQAATestingID(pendingPayment)}}" *ngIf="pendingPayment.echeck && !pendingPayment.isRefund"
            [formGroup]="transactionsForm" style="background: none; box-shadow: none"
            class="echeck-tour-step-4 check-number-margin sf-mask">
        <ng-container formGroupName="{{pendingPayment.echeck.echeckID}}">
            <ng-container formGroupName="echeck">
                <div [ngStyle]="{'cursor': pendingPayment.inDialogMode ? 'not-allowed' : ''}">
                    <input #checkNumberInput id="{{pendingPayment.echeck.echeckID}}" type="text" role="textbox"
                        formControlName="checkNumber" maxlength="10" style="width: 110px"
                        [ngStyle]="{'pointer-events': pendingPayment.inDialogMode ? 'none' : ''}"
                        [ngClass]="calculateCheckNumberClass(pendingPayment.echeck.echeckID, pendingPayment.approved)"
                        placeholder="Required"
                        [readonly]="!pendingPayment.status.checkNumberAndMemoEnabled || pendingPayment.inDialogMode"
                        [ngbTypeahead]="suggestedCheckNumber" container="body" [focusFirst]="false"
                        (click)="clickCheckNumber(pendingPayment.echeck.echeckID)" (focus)="getSortedEcheckRowIDs()"
                        (blur)="doEcheckUpdate($event, pendingPayment)"
                        (keydown)="focusNextEcheckNumber($event, pendingPayment.echeck.echeckID)"
                        (keypress)="focusNextEcheckNumber($event, pendingPayment.echeck.echeckID)">
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #memoTemplate let-pendingPayment>
    <!--    only if not an echeck refund -->
    <div id="memo-{{getQAATestingID(pendingPayment)}}"
            *ngIf="pendingPayment.pendingPaymentMethodType === 'ECHECK' && hasEcheckMemo(pendingPayment.echeck.echeckID) && !pendingPayment.isRefund"
            [ngbTooltip]="getMemoTooltip(pendingPayment.echeck.echeckID)" placement="left" container="body">
        <button class="memo-icon"
                [ngClass]="{'disabled': !pendingPayment.status.checkNumberAndMemoEnabled || pendingPayment.inDialogMode}"
                [disabled]="!pendingPayment.status.checkNumberAndMemoEnabled || pendingPayment.inDialogMode"
                (click)="clickMemo(pendingPayment.echeck.echeckID)" aria-label="Memo">
            <fa-icon [icon]="['far', 'clipboard']"></fa-icon>
        </button>
    </div>
    <div id="memo-{{getQAATestingID(pendingPayment)}}"
            *ngIf="pendingPayment.pendingPaymentMethodType === 'ECHECK' && !hasEcheckMemo(pendingPayment.echeck.echeckID)"
            ngbTooltip="Memo missing" placement="left" container="body">
        <button class="memo-missing-icon" [ngClass]="{'disabled': !pendingPayment.status.checkNumberAndMemoEnabled}"
                [disabled]="!pendingPayment.status.checkNumberAndMemoEnabled"
                (click)="clickMemo(pendingPayment.echeck.echeckID)" aria-label="Memo">
            <fa-icon [icon]="['fal', 'notes-medical']"></fa-icon>
        </button>
    </div>
</ng-template>

<ng-template #failedReasonTemplate let-pendingPayment>
    <div id="failed-reason-{{getQAATestingID(pendingPayment)}}" ngbTooltip="{{pendingPayment.errorMessage}}"
         placement="left" container="body"
    >{{pendingPayment.errorMessage}}</div>
</ng-template>

<ng-template #statusTemplate let-pendingPayment>
    <span id="status-{{getQAATestingID(pendingPayment)}}" class="credit-card-tour-step-3"
            *ngIf="pendingPayment.isChild || pendingPayment.pendingPaymentMethodType === 'ECHECK' || pendingPayment.pendingPaymentMethodType === 'ACH' || (pendingPayment.pendingPaymentMethodType === 'CREDIT_CARD' && pendingPayment.inDialogMode)"
            class="badge" style="cursor: default; line-height: 1.25;" [ngClass]="calculatePillStyles(pendingPayment)"
            [ngbTooltip]="getTooltip(null, 'status', pendingPayment.isChild || pendingPayment.pendingPaymentMethodType === 'ACH')"
            placement="left" container="body">{{calculatePillText(pendingPayment)}}
        <fa-icon
                *ngIf="pendingPayment.pendingPaymentMethodType === 'ECHECK' && pendingPayment.echeck.overrideCollections"
                [icon]="['far', 'info-circle']" ngbTooltip="Payment ignored by collection rules" placement="left"
                container="body"></fa-icon>
    </span>
    <span id="status-{{getQAATestingID(pendingPayment)}}"
            *ngIf="pendingPayment.pendingPaymentMethodType === 'CREDIT_CARD' && (!pendingPayment.errorMessage || (pendingPayment.errorMessage && pendingPayment.pendingPaymentType !== 'RECORDING')) && !pendingPayment.inDialogMode"
            class="btn badge credit-card-tour-step-3" style="line-height: 1.25;" [ngClass]="calculatePillStyles(pendingPayment)"
            ngbTooltip="Payment will be processed at end of day" placement="left"
            container="body">{{calculatePillText(pendingPayment)}}</span>
<!--    <button id="status-{{getQAATestingID(pendingPayment)}}"-->
<!--            *ngIf="pendingPayment.pendingPaymentType === 'RECORDING' && pendingPayment.pendingPaymentMethodType === 'CREDIT_CARD' && pendingPayment.errorMessage && !pendingPayment.inDialogMode"-->
<!--            class="btn badge credit-card-tour-step-3" style="line-height: 1.25;" [ngClass]="calculatePillStyles(pendingPayment)"-->
<!--            (click)="paymentButtonClicked(pendingPayment.failedTransactionItemID)"-->
<!--            ngbTooltip="Click to view past due credit card payment details" placement="left"-->
<!--            container="body">{{calculatePillText(pendingPayment)}}</button>-->
</ng-template>

<ng-template #approveTemplate let-pendingPayment>
    <ng-container [formGroup]="transactionsForm">
        <div id="approve-{{getQAATestingID(pendingPayment)}}"
                *ngIf="pendingPayment.pendingPaymentMethodType === 'ECHECK'"
                formGroupName="{{pendingPayment.echeck.echeckID}}" style="background: none">
            <ng-container formGroupName="echeck">
                <div *ngIf="!pendingPayment.inDialogMode"
                        [ngbTooltip]="getTooltip(pendingPayment.echeck.echeckID, 'approve')" placement="left"
                        container="body" class="echeck-tour-step-5">
                    <button class="approve-button"
                            [ngClass]="{'disabled': disableButton(pendingPayment)}"
                            [disabled]="disableButton(pendingPayment)" aria-label="Approve"
                            (click)="paymentButtonClicked(pendingPayment)">
                        <fa-icon [icon]="approveButtonIcons[pendingPayment.status.type]"></fa-icon>
                    </button>
                </div>
                <div *ngIf="pendingPayment.inDialogMode && (pendingPayment.status.type === 'APPROVE_1' || pendingPayment.status.type === 'APPROVE_2')"
                        [ngbTooltip]="'Override eCheck collection rules'" placement="left" container="body">
                    <input type="checkbox" formControlName="overrideCollections">
                </div>
            </ng-container>
        </div>
    </ng-container>
</ng-template>

<ng-template #retryTemplate let-pendingPayment>
    <ng-container [formGroup]="transactionsForm">
<!--        <div id="retry-{{getQAATestingID(pendingPayment)}}" *ngIf="pendingPayment.pendingPaymentType === 'RECORDING' && pendingPayment.pendingPaymentMethodType === 'CREDIT_CARD' && pendingPayment.errorMessage && !pendingPayment.inDialogMode" style="background: none"-->
<!--             ngbTooltip="Click to view past due credit card payment details and to retry payment"-->
<!--             placement="left" container="body">-->
<!--            <button-->
<!--                    class="approve-button"-->
<!--                    (click)="paymentButtonClicked(pendingPayment.failedTransactionItemID)"-->
<!--            >-->
<!--                <fa-icon [icon]="approveButtonIcons[pendingPayment.status.type]"></fa-icon>-->
<!--            </button>-->
<!--        </div>-->
    </ng-container>
</ng-template>

<!--Echeck Tour -->
<sf-tour *ngIf="showTours && selectedPaymentType === 'ECHECK'" (afterInit)="setPendingEcheckPaymentsTour($event.tour)" [tourName]="'approve-pending-echeck-payments'" (afterTour)="approvePendingPaymentsTourFinished('SHOW_TOUR_APPROVE_PENDING_ECHECK_PAYMENTS')">
    <sf-tour-step title="Approve Pending Payments"
                  text="We updated the Pending Payments view to make it easier to focus on payments that need your attention. Click Next to learn more."
    ></sf-tour-step>
    <sf-tour-step title="Approve Pending Payments"
                  text="Each payment type is in its own tab so you will only see information that is relevant to that payment type. The numbers in each tab indicate how many payments need your attention."
                  attachTo="label.echeck-tour-step-2 bottom"
    ></sf-tour-step>
    <sf-tour-step title="Approve Pending Payments"
                  text="Your organization's pending payments display here. Pending payments should be approved the same day as recording to avoid service suspension."
                  attachTo=".ag-center-cols-container .ag-row-first bottom"
    ></sf-tour-step>
    <sf-tour-step title="Approve Pending Payments"
                  text="For each pending eCheck payment, a check number must be entered. The check number should be unique for each payment."
                  attachTo=".ag-row-first div.echeck-tour-step-4 bottom"
    ></sf-tour-step>
    <sf-tour-step title="Approve Pending Payments"
                  text="Once the check number is entered, the payment can be approved by clicking on the Approve button."
                  attachTo=".ag-row-first div.echeck-tour-step-5 bottom"
    ></sf-tour-step>
</sf-tour>

<!--Credit Card Tour-->
<sf-tour *ngIf="showTours && selectedPaymentType === 'CREDIT_CARD'" (afterInit)="setPendingCreditCardPaymentsTour($event.tour)" [tourName]="'approve-pending-credit-card-payments'" (afterTour)="approvePendingPaymentsTourFinished('SHOW_TOUR_APPROVE_PENDING_CREDIT_CARD_PAYMENTS')">
    <sf-tour-step title="Approve Pending Payments"
                  text="Your organization’s failed credit card payments display here. Payment issues should be resolved as soon as possible to avoid service suspension."
                  attachTo=".ag-center-cols-container .ag-row-first bottom"
    ></sf-tour-step>
    <sf-tour-step title="Approve Pending Payments"
                  text="You can change the selected card or add a new credit card here."
                  attachTo=".ag-row-first div.credit-card-tour-step-2 bottom"
    ></sf-tour-step>
    <sf-tour-step title="Approve Pending Payments"
                  text="The payment will be processed during the next payment cycle."
                  attachTo=".ag-row-first .credit-card-tour-step-3 bottom"
    ></sf-tour-step>
</sf-tour>

<sf-tour *ngIf="showTours && selectedPaymentType === 'ECHECK'" (afterInit)="setApprovedEcheckChangingTour($event.tour)" [tourName]="'approved-echeck-payment-account-changes'" (afterTour)="approvePendingPaymentsTourFinished('SHOW_TOUR_APPROVED_ECHECK_PAYMENT_ACCOUNT_CHANGES')">
    <sf-tour-step title="eCheck Payment Account Changes"
                  text="Payment accounts can now be modified after an eCheck is approved. Changes can only be made prior to processing, and changing the payment account will require you to re-enter the check number and memo information. The eCheck will also need to be approved again."
        nextText="Close">
    </sf-tour-step>
</sf-tour>
