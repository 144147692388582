import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { RpcClientService } from "@sf/common";
import { BaseModalComponent, ModalButton } from "@sf/common";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Injectable({
    providedIn: "root"
})
export class AdminService {
    constructor(
        protected _rpc: RpcClientService,
        private _modalService: NgbModal
    ) {}

    /** Public Methods **/
    importPackage(
        environment: string,
        destinationSubmitterID: string,
        destinationRecipientID: string,
        packageIDs: string[],
        blankPages: boolean,
        autoSubmit: boolean
    ): Observable<null> {
        return this._rpc.makeRequest<null>("admin", "importPackage", {
            env: environment,
            destinationSubmitterID: destinationSubmitterID,
            destinationRecipientID: destinationRecipientID,
            packageIDs: packageIDs,
            blankPages: blankPages,
            autoSubmit: autoSubmit
        });
    }

    getSubmitters(search: string): Observable<any[]> {
        return this._rpc.makeRequest("admin", "getSubmitters", {
            submitter: search
        });
    }

    getEnvironments() {
        return this._rpc.makeRequest<any>("admin", "getEnvironments", {});
    }

    getImportPackageJobs() {
        return this._rpc.makeRequest<any>("admin", "getImportPackageJobs", {});
    }

    testXslFile(filename: string) {
        return this._rpc.makeRequest("admin", "testXslFile", {
            filename: filename
        });
    }

    testDocFile(filename: string) {
        return this._rpc.makeRequest("admin", "testDocFile", {
            filename: filename
        });
    }

    importTestPackage(
        packageIDs: string[],
        submitterID: string,
        environment: string,
        xslEnvironments: string[]
    ): Observable<null> {
        return this._rpc.makeRequest<null>("admin", "importTestPackage", {
            packageIDs: packageIDs,
            submitterID: submitterID,
            environment: environment,
            xslEnvironments: xslEnvironments
        });
    }

    getDistinctTestJobFieldValues() {
        return this._rpc.makeRequest("admin", "getDistinctTestJobFieldValues", {
            username: "",
            sPackageID: "",
            xslEnvironment: "",
            enabled: true
        });
    }

    getImportedTestPackageIDs(
        username: string,
        sPackageID: string,
        sourcePackageID: string,
        sourceEnvironment: string,
        sSubmitterID: string,
        recipientID: string,
        recipientSystem: string,
        recipientClass: string,
        xslFilename: string,
        xslEnvironment: string,
        sState: string,
        importDate: string
    ) {
        return this._rpc.makeRequest("admin", "getImportedTestPackageIDs", {
            username: username,
            sPackageID: sPackageID,
            sourcePackageID: sourcePackageID,
            sourceEnvironment: sourceEnvironment,
            sSubmitterID: sSubmitterID,
            recipientID: recipientID,
            recipientSystem: recipientSystem,
            recipientClass: recipientClass,
            xslFilename: xslFilename,
            xslEnvironment: xslEnvironment,
            sState: sState,
            importDate: importDate,
            enabled: true
        });
    }

    getImportedTestPackageDetails(packageID: string) {
        return this._rpc.makeRequest("admin", "getImportedTestPackageDetails", {
            username: "",
            sPackageID: packageID,
            sourcePackageID: "",
            sourceEnvironment: "",
            sSubmitterID: "",
            recipientID: "",
            recipientSystem: "",
            recipientClass: "",
            xslFilename: "",
            xslEnvironment: "",
            sState: "",
            importDate: null,
            enabled: true
        });
    }

    runTransmissionDataDifferencesJob(
        jobName: string,
        emailList: string,
        packageImportedByUsername: string,
        packageID: string,
        sSourcePackageID: string,
        sourceEnvironment: string,
        sSubmitterID: string,
        recipientID: string,
        recipientSystem: string,
        recipientClass: string,
        xslFilename: string,
        xslEnvironment: string,
        sState: string,
        previousJob: string,
        preview: boolean
    ) {
        return this._rpc.makeRequest(
            "admin",
            "runTransmissionDataDifferencesJob",
            {
                jobName: jobName,
                emailList: emailList,
                packageImportedByUsername: packageImportedByUsername,
                packageID: packageID,
                sSourcePackageID: sSourcePackageID,
                sourceEnvironment: sourceEnvironment,
                sSubmitterID: sSubmitterID,
                recipientID: recipientID,
                recipientSystem: recipientSystem,
                recipientClass: recipientClass,
                xslFilename: xslFilename,
                xslEnvironment: xslEnvironment,
                sState: sState,
                previousJob: previousJob,
                preview: preview
            }
        );
    }

    getTestJobIDs(
        jobCreatedByUsername: string,
        jobName: string,
        packageImportedByUsername: string,
        sPackageID: string,
        sSourcePackageID: string,
        sourceEnvironment: string,
        sSubmitterID: string,
        sRecipientID: string,
        recipientSystem: string,
        recipientClass: string,
        xslFilename: string,
        xslEnvironment: string,
        sState: string,
        maxResults: number
    ) {
        return this._rpc.makeRequest("admin", "getTestJobIDs", {
            jobCreatedByUsername: jobCreatedByUsername,
            jobName: jobName,
            packageImportedByUsername: packageImportedByUsername,
            sPackageID: sPackageID,
            sSourcePackageID: sSourcePackageID,
            sourceEnvironment: sourceEnvironment,
            sSubmitterID: sSubmitterID,
            sRecipientID: sRecipientID,
            recipientSystem: recipientSystem,
            recipientClass: recipientClass,
            xslFilename: xslFilename,
            xslEnvironment: xslEnvironment,
            sState: sState,
            maxResults: maxResults
        });
    }

    getTestJobDetailsByID(jobID: string) {
        return this._rpc.makeRequest("admin", "getTestJobDetailsByID", {
            jobID: jobID
        });
    }

    getTestJobDetails(
        jobName: string,
        packageImportedByUsername: string,
        sPackageID: string,
        sSourcePackageID: string,
        sourceEnvironment: string,
        sSubmitterID: string,
        sRecipientID: string,
        recipientSystem: string,
        recipientClass: string,
        xslFilename: string,
        xslEnvironment: string,
        sState: string,
        maxResults: number
    ) {
        return this._rpc.makeRequest("admin", "getTestJobDetails", {
            jobName: jobName,
            packageImportedByUsername: packageImportedByUsername,
            sPackageID: sPackageID,
            sSourcePackageID: sSourcePackageID,
            sourceEnvironment: sourceEnvironment,
            sSubmitterID: sSubmitterID,
            sRecipientID: sRecipientID,
            recipientSystem: recipientSystem,
            recipientClass: recipientClass,
            xslFilename: xslFilename,
            xslEnvironment: xslEnvironment,
            sState: sState,
            maxResults: maxResults
        });
    }

    getCommonCustomPropertyKeys() {
        return this._rpc.makeRequest(
            "admin",
            "getCommonCustomPropertyKeys",
            {}
        );
    }

    getCommonCustomPropertyValues() {
        return this._rpc.makeRequest(
            "admin",
            "getCommonCustomPropertyValues",
            {}
        );
    }

    getTodayRecordedDocTotals() {
        return this._rpc.makeRequest("admin", "getTodayRecordedDocTotals", {});
    }

    getPackageAdminSummary(
        startDate: string,
        endDate: string,
        groupByRecipient: boolean,
        showDisabled: boolean,
        showSample: boolean,
        showDraft: boolean,
        showReady: boolean,
        showRejected: boolean,
        showSent: boolean,
        showAccepted: boolean,
        forceLive: boolean,
        detailState: any
    ) {
        return this._rpc.makeRequest("admin", "getPackageAdminSummary", {
            startDate,
            endDate,
            groupByRecipient,
            showDisabled,
            showSample,
            showDraft,
            showReady,
            showRejected,
            showSent,
            showAccepted,
            forceLive,
            detailState
        });
    }

    searchOrganizations(
        queryString: string,
        limit: number,
        serviceFilter?: string[],
        adminRightsOnly?: any,
        enhancedResults?: any,
        status?: any,
        includeArchived?: boolean
    ) {
        return this._rpc.makeRequest("search", "searchBasicOrganizations", {
            query: queryString,
            limit: limit,
            services: serviceFilter,
            adminRightsOnly: adminRightsOnly,
            enhancedResults: enhancedResults,
            byStatus: status,
            includeArchived: includeArchived
        });
    }

    displayJobMessageDialog(jobMessages: any[]) {
        let jobMessageDisplay = "";
        jobMessages.forEach((jobMessage) => {
            jobMessageDisplay += "~~~~\n" + jobMessage.date;
            if (jobMessage.messageCreator) {
                jobMessageDisplay += " - " + jobMessage.messageCreator + "\n";
            }
            jobMessageDisplay += jobMessage.text + "\n\n";
        });
        if (jobMessageDisplay === "") {
            jobMessageDisplay = "No messages to display";
        }

        let modalInstance = this._modalService.open(BaseModalComponent, {
            size: "xl"
        }).componentInstance;

        const primary: ModalButton = {
            text: "Close"
        };

        modalInstance.title = "Job Messages";
        modalInstance.primary = primary;
        modalInstance.message = `<pre>${jobMessageDisplay}</pre>`;
    }

    getLiveCountyList(state?: string) {
        return this._rpc.makeRequest("admin", "getLiveCountyList", { state });
    }

    getZipCodeDownload(state?: string) {
        let options: any = {};
        if (state) {
            options.state = state;
        }
        return this._rpc.makeRequest("admin", "getZipCodeDownload", options);
    }

    getZipCodeUpload(zipCodes: string, overwriteAll: boolean, state?: string) {
        let options: any = {
            csv: zipCodes,
            overwriteAll: overwriteAll
        };
        if (state) {
            options.state = state;
        }
        return this._rpc.makeRequest("admin", "updateZipCodeFips", options);
    }

    getRecipientsForZipCode(zipCode: string) {
        return this._rpc.makeRequest("admin", "getRecipientsForZipCode", {
            zipCode: zipCode
        });
    }

    getRecipientsForAddress(
        streetAddress: string,
        city: string,
        state: string,
        zipCode: string
    ) {
        return this._rpc.makeRequest("admin", "getRecipientsForAddress", {
            streetAddress: streetAddress,
            city: city,
            state: state,
            zipCode: zipCode
        });
    }

    getOrganizations(orgIDs: string[]) {
        return this._rpc.makeRequest("userorg", "getOrganizations", { orgIDs });
    }

    getOrganizationsToManage(
        type: string,
        state: string,
        vendor: string,
        showDisabled: boolean
    ) {
        return this._rpc.makeRequest("admin", "getOrganizationsToManage", {
            type,
            state,
            vendor,
            showDisabled
        });
    }

    getCOIReviewQueueCount() {
        return this._rpc.makeRequest("admin", "getCOIReviewQueueCount");
    }
}
