import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {
    BannerNotificationCategory,
    BannerNotificationService,
    ConfirmSurcharge,
    PendingPaymentsCountsObj,
    PendingPaymentsDataService,
    PendingPaymentsDisplaySettingsObject,
    PendingPaymentsNewService,
    PendingPaymentsOrgFilterSelectionObject,
    PendingPaymentStatus,
    Semaphore,
    StripeService,
    UserOrgService,
    UserorgSubscriptionService
} from "@sf/userorg/common";
import {
    BehaviorSubject,
    combineLatest,
    forkJoin,
    fromEvent,
    iif,
    merge,
    Observable,
    of,
    Subject,
    Subscription,
    throwError
} from "rxjs";
import {
    ConfirmationModalComponent,
    GrowlService,
    SessionOrganization,
    SessionService,
    sortBy,
    TemplateRendererComponent,
    TourService,
    UserSettingsService
} from "@sf/common";
import {
    catchError,
    concatAll,
    concatMap,
    debounceTime,
    defaultIfEmpty,
    delay,
    distinctUntilChanged,
    filter,
    first,
    map,
    mergeMap,
    shareReplay,
    skip,
    startWith,
    switchMap,
    take,
    takeUntil,
    tap,
    toArray
} from "rxjs/operators";
import { dayjs } from "@sf/common";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
    ColDef,
    ColumnApi,
    FirstDataRenderedEvent,
    GetRowIdParams,
    GridApi
} from "@ag-grid-community/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { EditEcheckMemoDialogComponent } from "../../dialogs/edit-echeck-memo-dialog/edit-echeck-memo-dialog.component";
import * as clone from "clone";
import { PendingPaymentsLoadingOverlayComponent } from "../pending-payments-loading-overlay/pending-payments-loading-overlay.component";
import { PendingPaymentsNoRowsOverlayComponent } from "../pending-payments-no-rows-overlay/pending-payments-no-rows-overlay.component";
import { DuplicateCheckNumberComponent } from "../../dialogs/duplicate-check-number/duplicate-check-number.component";
import { PendingPaymentsFeeDetailDialogComponent } from "../../dialogs/pending-payments-fee-detail-dialog/pending-payments-fee-detail-dialog.component";
import { PastDueCreditCardPaymentDialogComponent } from "../../dialogs/past-due-credit-card-payment-dialog/past-due-credit-card-payment-dialog.component";
import { AddEditPaymentAccountDialogComponent } from "../../dialogs/add-edit-payment-account-dialog/add-edit-payment-account-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";
import {
    PaymentNavTickersService,
    SubmitterFeeService,
    SubmitterPaymentService
} from "@sf/submitter/common";
import { BulkCreditCardRetryDialogComponent } from "../../dialogs/bulk-credit-card-retry-dialog/bulk-credit-card-retry-dialog.component";
import { ConfirmSurchargeDialogComponent } from "../../dialogs/confirm-surcharge-dialog/confirm-surcharge-dialog.component";
import { EcheckRefundAddressConfirmationDialogComponent } from "../../dialogs/echeck-refund-address-confirmation-dialog/echeck-refund-address-confirmation-dialog.component";
import { IconName, IconPrefix } from "@fortawesome/fontawesome-svg-core";
import { ResizeEvent } from "@vimeo/player";

declare const window: any;

@Component({
    selector: "sf-pending-payments",
    templateUrl: "./pending-payments.component.html",
    styleUrls: ["./pending-payments.component.scss"]
})
export class PendingPaymentsComponent
    implements OnInit, OnDestroy, AfterViewInit
{
    @HostListener("window:resize")
    onWindowResize(event: ResizeEvent) {
        this._gridApi?.sizeColumnsToFit();
    }

    /** Private Variables **/
    private _pendingPaymentsDisplayForm: BehaviorSubject<FormGroup> =
        new BehaviorSubject(null);

    private _onDestroy: Subject<void> = new Subject<void>();
    private _pendingPaymentsChanged: Subject<void> = new Subject<undefined>();
    private _newCreditCardAdded: BehaviorSubject<any> =
        new BehaviorSubject<any>(null);

    private _selectAllOrgsOptions: PendingPaymentsOrgFilterSelectionObject = {
        value: "All",
        option: "none",
        title: "All Organizations",
        label: "All Organizations"
    };
    private _allOrgsIDArray: string[];
    private _gridApi: GridApi;
    private _columnApi: ColumnApi;
    private static readonly _CHECK_NUMBER_REGEX = /^[a-zA-Z]?[0-9]+$/;
    private _overlayOrg: string = null;
    private _savePaymentAccount: boolean = false;
    private _savePaymentAccounts: boolean = false;
    private _retryPayment: boolean = false;
    private _retryPayments: boolean = false;
    private _overlayPaymentType: string = "";
    private _addedNewCreditCard: boolean = false;
    private _orgChanged: boolean = true;
    private _orgPaymentTypeOptionsArr: string[] = [];
    private _defaultEcheckSort: any = [
        {
            colId: "approve",
            sort: "asc"
        },
        {
            colId: "status",
            sort: "desc"
        }
    ];
    private _defaultCreditCardSort: any = [
        {
            colId: "retry",
            sort: "asc"
        },

        {
            colId: "status",
            sort: "asc"
        }
    ];
    private _echeckOnlyPendingPaymentColumns: string[] = [
        "checkNumber",
        "memo",
        "approve"
    ];
    private _creditCardOnlyPendingPaymentColumns: string[] = [
        "failedReason",
        "retry"
    ];
    private _masterOrgSelectorData: PendingPaymentsOrgFilterSelectionObject[];
    private _firstClick: boolean = true;
    private _initialSelectedOrg: string;
    private _hasUnapprovedEchecks: boolean;
    private _multiplePaymentsSelected: boolean;
    private _allPendingPayments: any[];
    private _allPendingPaymentsUsed: boolean = false;
    private _waitingForGridApi: boolean = false;
    private _pendingPaymentsLength: number;
    private _hasPaymentTypeFilterOption: boolean;
    private _echeckAmts: string[] = [];
    private _initialRouting: boolean = true;

    /** Public Variables **/
    hideApprovedPayments: boolean = false;
    /*
    paymentTypeFilterOptions: PendingPaymentsFilterOptionsInterface[] = [
        { id: "ACH", label: "ACH Payments" },
        { id: "ECHECK", label: "E-check Payments" },
        { id: "CREDIT_CARD", label: "Credit Card Payments" }
    ];
    */
    approveButtonIcons: { [key: string]: [IconPrefix, IconName] } = {
        APPROVE_1: ["far", "chevron-circle-right"],
        APPROVE_2: ["far", "chevron-circle-right"],
        APPROVED_NOT_PRINTED: ["far", "edit"],
        EDIT_PAYMENT: ["far", "check"],
        NO_ACTION: ["far", "spinner"],
        PAYMENT_PROCESSING: ["far", "hourglass-half"],
        PROCESSING: ["far", "spinner"],
        REFUND: ["far", "chevron-circle-right"],
        WAITING: ["far", "user-clock"]
    };
    selectedPaymentsValid: boolean = false;
    pendingPaymentsLoaded: boolean = false;
    pendingPaymentsLoadingCount: number = 0;
    pendingPaymentCountsLoaded: boolean = false;
    delinquentCount: number = 0;
    delinquentTotal: number = 0;
    delinquentLabel: string;
    frameworkComponents: any;
    hasSelectedRows: boolean = false;
    loadingOverlayComponent: any;
    loadingOverlayComponentParams: any;
    noRowsOverlayComponent: any;
    noRowsOverlayComponentParams: any;
    suggestedCheckNumbers: string[] = [];
    selectedPendingPaymentsIDs: string[];
    selectedEcheckPendingPayments: any[] = [];
    selectedCreditCardPendingPayments: any[] = [];
    transactionsForm: FormGroup;
    pendingCreditCardPaymentsTour: any = null;
    pendingEcheckPaymentsTour: any = null;
    approvedEcheckChangingTour: any = null;
    defaultColumnDef: Partial<ColDef>;
    autoGroupColumnDef: Partial<ColDef>;
    showEcheckSelector: boolean;
    showACHSelector: boolean;
    showCreditCardSelector: boolean;
    hasDelinquentEcheckPayments: boolean = false;
    hasDelinquentCreditCardPayments: boolean = false;
    hasAnyEcheckPayments: boolean = false;
    hasAnyCreditCardPayments: boolean = false;
    orgSelectorData: PendingPaymentsOrgFilterSelectionObject[] = [];
    selectedOrgEcheckCount: number;
    selectedOrgCreditCardCount: number;
    selectedOrgEcheckCountClass: string;
    selectedOrgCreditCardClass: string;
    selectedPaymentType: string;
    uiPaymentAccountTypesMap: any;
    canClearSelectedOrg: boolean;
    showTours: boolean = false;
    //showCCFooter: boolean = true; //by default, show it

    /** Observables **/
    stop$: Observable<any> = merge(
        this._onDestroy,
        this._pendingPaymentsChanged
    );

    pendingPayments$: Observable<any> =
        this._pendingPaymentsDataService.uiPendingPayments$.pipe(
            delay(0),
            map((payments: any) => {
                this.pendingPaymentsLoaded = false;
                if (typeof payments == "string" && payments == "PENDING") {
                    this.pendingPaymentsLoadingCount =
                        this.pendingPaymentsLoadingCount + 1;
                    return null;
                } else {
                    this.pendingPaymentsLoadingCount =
                        this.pendingPaymentsLoadingCount - 1;
                    return payments;
                }
            }),
            map((payments) => {
                this._pendingPaymentsChanged.next();
                this.transactionsForm = this._fb.group({});
                this.delinquentCount = 0;
                this.delinquentTotal = 0;
                if (payments && payments.length > 0) {
                    this._setupPendingPaymentLabels(payments);
                    this._setupPendingPaymentForms(payments); // sets up form controls for each echeck and/or CC pending payment object
                    //delinquent count gets modified in above calls, so need to have this here
                    this.delinquentLabel = `${this.delinquentCount} ${
                        this.delinquentCount === 1 ? "Payment" : "Payments"
                    } past due`;
                    return this._setupPendingPaymentTreeData(payments);
                } else {
                    this.delinquentLabel = `${this.delinquentCount} ${
                        this.delinquentCount === 1 ? "Payment" : "Payments"
                    } past due`;
                    return this._setupPendingPaymentTreeData(payments);
                }
            }),
            tap((payments: any) => {
                this._pendingPaymentsLength = payments ? payments.length : 0;

                let paymentType: string;

                if (this._pendingPaymentsLength > 0) {
                    if (
                        payments.some(
                            (payment: any) =>
                                payment.pendingPaymentMethodType === "ACH"
                        )
                    ) {
                        paymentType = "ACH";
                    } else if (
                        payments.some(
                            (payment: any) =>
                                payment.pendingPaymentMethodType ===
                                "CREDIT_CARD"
                        )
                    ) {
                        paymentType = "CREDIT_CARD";
                    } else if (
                        payments.some(
                            (payment: any) =>
                                payment.pendingPaymentMethodType === "ECHECK"
                        )
                    ) {
                        paymentType = "ECHECK";
                    }
                } else if (
                    !this._hasPaymentTypeFilterOption &&
                    this._pendingPaymentsLength === 0
                ) {
                    if (this.showEcheckSelector) {
                        paymentType = "ECHECK";
                    } else if (this.showCreditCardSelector) {
                        paymentType = "CREDIT_CARD";
                    } else if (this.showACHSelector) {
                        paymentType = "ACH";
                    }
                }

                this._setSelectedPaymentType(paymentType);

                this._hasUnapprovedEchecks = false;
                if (this._pendingPaymentsLength > 0) {
                    this._hasUnapprovedEchecks = payments.some(
                        (payment: any) =>
                            payment.pendingPaymentMethodType === "ECHECK" &&
                            !payment.approved &&
                            (payment.status ===
                                PendingPaymentStatus.APPROVE_1 ||
                                payment.status ===
                                    PendingPaymentStatus.APPROVE_2 ||
                                payment.status === PendingPaymentStatus.REFUND)
                    ); // So we display the tour when there is an actual payment to approve
                }

                if (this._gridApi) {
                    this._finalizeGridSetup();
                } else {
                    this._waitingForGridApi = true;
                }
                // setTimeout(() => {
                if (this.pendingPaymentsLoadingCount < 0)
                    this.pendingPaymentsLoadingCount = 0;
                if (this.pendingPaymentsLoadingCount == 0)
                    this.pendingPaymentsLoaded = true;
                if (this._pendingPaymentsLength <= 0) {
                    this.pendingPaymentCountsLoaded = true;
                    this.pendingPaymentsLoaded = true;
                    this._gridApi?.hideOverlay();
                }
                // }, 200);
            })
        );

    getUserPaymentAccountTypes$: Observable<any> = this._route.data.pipe(
        switchMap((resolvedData: any) => {
            if (this.dialogMode) {
                return this._pendingPaymentsNewService.getPaymentAccountTypes(
                    [this.dialogOrg.id],
                    true
                );
            } else {
                return of({
                    uiPaymentAccountTypesMap: resolvedData.resolved[0]
                        ? resolvedData.resolved[0]
                        : {},
                    allSessionOrganizations: resolvedData.resolved[1]
                    //allPendingPayments: resolvedData.resolved[2]
                });
            }
        }),
        // map((resolvedData: any) => {
        //     if (this.dialogMode) {
        //         let dialogResolvedData: any =  {
        //             uiPaymentAccountTypesMap: { paymentAccountTypesMap : {} },
        //             allSessionOrganizations: [],
        //             allPendingPayments: []
        //         }
        //         dialogResolvedData.uiPaymentAccountTypesMap.paymentAccountTypesMap[this.dialogOrg.id] = this._pendingPaymentsNewService.getPaymentAccountTypes(
        //                 [this.dialogOrg.id],
        //                 true
        //         );
        //         return dialogResolvedData;
        //     } else {
        //         return {
        //             uiPaymentAccountTypesMap: resolvedData.resolved[0]
        //                     ? resolvedData.resolved[0]
        //                     : {},
        //             allSessionOrganizations: resolvedData.resolved[1],
        //             allPendingPayments: resolvedData.resolved[2]
        //         }
        //     };
        // }),
        map((paymentAccountData: any) => {
            if (this.dialogMode) {
                paymentAccountData = {
                    uiPaymentAccountTypesMap: {
                        paymentAccountTypesMap:
                            paymentAccountData.paymentAccountTypesMap
                    },
                    allSessionOrganizations: [],
                    allPendingPayments: []
                };
            }
            this.uiPaymentAccountTypesMap =
                paymentAccountData.uiPaymentAccountTypesMap.paymentAccountTypesMap;
            if (!this._allPendingPaymentsUsed) {
                this._allPendingPayments =
                    paymentAccountData.allPendingPayments;
                // logic to update uiPaymentAccountTypesMap if we are returning pending payments but the account type has been removed PS-10458
                // this._allPendingPayments.forEach((payment: any) => {
                //     switch (payment.pendingPaymentMethodType) {
                //         case "ECHECK":
                //             if (
                //                 !this.uiPaymentAccountTypesMap[
                //                     payment.organizationID
                //                 ].hasEcheckAccount
                //             ) {
                //                 this.uiPaymentAccountTypesMap[
                //                     payment.organizationID
                //                 ].hasEcheckAccount = true;
                //             }
                //             if (
                //                 !this.uiPaymentAccountTypesMap["none"]
                //                     .hasEcheckAccount
                //             ) {
                //                 this.uiPaymentAccountTypesMap[
                //                     "none"
                //                 ].hasEcheckAccount = true;
                //             }
                //             break;
                //         case "CREDIT_CARD":
                //             if (
                //                 !this.uiPaymentAccountTypesMap[
                //                     payment.organizationID
                //                 ].hasCreditCardAccount
                //             ) {
                //                 this.uiPaymentAccountTypesMap[
                //                     payment.organizationID
                //                 ].hasCreditCardAccount = true;
                //             }
                //             if (
                //                 !this.uiPaymentAccountTypesMap["none"]
                //                     .hasCreditCardAccount
                //             ) {
                //                 this.uiPaymentAccountTypesMap[
                //                     "none"
                //                 ].hasCreditCardAccount = true;
                //             }
                //             break;
                //         case "ACH":
                //             if (
                //                 !this.uiPaymentAccountTypesMap[
                //                     payment.organizationID
                //                 ].hasACHAccount
                //             ) {
                //                 this.uiPaymentAccountTypesMap[
                //                     payment.organizationID
                //                 ].hasACHAccount = true;
                //             }
                //             if (
                //                 !this.uiPaymentAccountTypesMap["none"]
                //                     .hasACHAccount
                //             ) {
                //                 this.uiPaymentAccountTypesMap[
                //                     "none"
                //                 ].hasACHAccount = true;
                //             }
                //             break;
                //         default:
                //             break;
                //     }
                // });
            }
            return paymentAccountData.allSessionOrganizations;
        })
    );

    orgSelectorDataInit$: Observable<any> =
        this.getUserPaymentAccountTypes$.pipe(
            take(1),
            switchMap((orgs: SessionOrganization[]) => {
                if (this.dialogMode) {
                    return of([this.dialogOrg]);
                } else {
                    return of(orgs);
                }
            }),
            mergeMap((orgs: SessionOrganization[]) => orgs),
            filter((org: SessionOrganization) => this._hasOrgPermission(org)),
            map((org: SessionOrganization) => this._getOrgSelectionObj(org)),
            toArray(),
            tap((orgs: PendingPaymentsOrgFilterSelectionObject[]) => {
                orgs.sort(sortBy("label"));
                this._allOrgsIDArray = orgs.map((org) => org.value);
                if (orgs.length > 1) {
                    orgs.unshift(this._selectAllOrgsOptions);
                    this._setAccountSelectorsVisible("none");
                    this._overlayOrg = this._selectAllOrgsOptions.label;
                } else if (orgs.length === 1) {
                    this._setAccountSelectorsVisible(orgs[0].option);
                    this._overlayOrg = orgs[0].label;
                }
                this._masterOrgSelectorData = orgs;

                // now we know what organizations we are interested in
                this.subscribeToUpdates();
            })
        );

    pendingPaymentsDisplay$: Observable<any> = this.orgSelectorDataInit$.pipe(
        switchMap((orgs: PendingPaymentsOrgFilterSelectionObject[]) => {
            let pendingPaymentsDisplaySettings: PendingPaymentsDisplaySettingsObject =
                this._getPendingPaymentsDisplaySettingsObject(orgs);
            return of(pendingPaymentsDisplaySettings);
        }),
        tap((displaySettings: PendingPaymentsDisplaySettingsObject) => {
            this._pendingPaymentsDisplayForm.next(
                this._fb.group(displaySettings)
            );
            this._initialSelectedOrg =
                this.pendingPaymentsDisplayForm.get("selectedOrgOption").value;
            this._refreshPendingPayments(true, true);
            this._catchFormChanges();
            this._setupGridOverlay();
        })
    );

    pendingPaymentsDisplayForm$: Observable<any> =
        this._pendingPaymentsDisplayForm.pipe(
            filter((form) => form !== null),
            shareReplay(1)
        );

    newCreditCardAdded$: Observable<any> = this._newCreditCardAdded.pipe(
        take(1),
        takeUntil(this._onDestroy),
        tap((addCardResponse: any) => {
            addCardResponse.clonedPayment.paymentAccountID =
                addCardResponse.modalResponse.id;
            this._savePaymentAccount = true;
            this._gridApi?.showLoadingOverlay();
        }),
        switchMap((addCardResponse: any) => {
            if (addCardResponse.isFailedLicense) {
                return of([true, addCardResponse]);
            } else {
                return this._pendingPaymentsNewService
                    .setPackagePaymentAccounts(
                        addCardResponse.clonedPayment.packageID,
                        addCardResponse.clonedPayment.paymentAccountID,
                        addCardResponse.clonedPayment.editableCategories
                    )
                    .pipe(
                        map((success: boolean) => [success, addCardResponse])
                    );
            }
        }),
        map(([success, addCardResponse]) => {
            return {
                success,
                addCardResponse
            };
        }),
        switchMap((res: any) => {
            if (res.success) {
                if (!res.addCardResponse.isFailedLicense) {
                    this._growlService.success("Payment account saved");
                    this._savePaymentAccount = false;
                }
                // only retry for license (Echeck, CC, ACH) or CC packages. Display an info growl for Echeck/ACH packages with link to view switched payment.
                if (
                    res.addCardResponse.clonedPayment.pendingPaymentType ===
                        "RECORDING" &&
                    (res.addCardResponse.clonedPayment.status?.type ===
                        "READY" ||
                        res.addCardResponse.clonedPayment.errorMessage?.trim()
                            .length === 0 ||
                        res.addCardResponse.clonedPayment
                            .pendingPaymentMethodType === "ACH" ||
                        res.addCardResponse.clonedPayment.paymentMethod ===
                            "ACH" ||
                        res.addCardResponse.clonedPayment
                            .pendingPaymentMethodType === "ECHECK")
                ) {
                    return of({
                        pendingPaymentLabel:
                            res.addCardResponse.clonedPayment.label,
                        switchedAccountType: "CREDIT_CARD",
                        switchedAccountLabel:
                            res.addCardResponse.modalResponse.label,
                        isRecording: true,
                        isNewCard: true,
                        hideLink:
                            res.addCardResponse.clonedPayment
                                .pendingPaymentMethodType === "CREDIT_CARD",
                        isImmediateRetry: false
                    });
                } else {
                    this._retryPayment = true;
                    if (
                        res.addCardResponse.clonedPayment
                            .pendingPaymentMethodType !== "CREDIT_CARD"
                    ) {
                        res.addCardResponse.clonedPayment.pendingPaymentMethodType =
                            "CREDIT_CARD";
                    }
                    if (
                        res.addCardResponse.clonedPayment.items &&
                        res.addCardResponse.clonedPayment.paymentMethod ===
                            "ACH"
                    ) {
                        delete res.addCardResponse.clonedPayment.items
                            .achReferenceID;
                        delete res.addCardResponse.clonedPayment.items
                            .contractID;
                        res.addCardResponse.clonedPayment.items = [
                            res.addCardResponse.clonedPayment.items
                        ];
                    } else if (!res.addCardResponse.clonedPayment.items) {
                        res.addCardResponse.clonedPayment.items = [
                            {
                                date: res.addCardResponse.clonedPayment.date,
                                feeTotals:
                                    res.addCardResponse.clonedPayment.feeTotals,
                                label: res.addCardResponse.clonedPayment.label,
                                pendingPaymentType:
                                    res.addCardResponse.clonedPayment
                                        .pendingPaymentType
                            }
                        ];
                    }
                    delete res.addCardResponse.clonedPayment.date;
                    if (res.addCardResponse.isFailedLicense)
                        res.addCardResponse.clonedPayment.isFailedLicense =
                            true;
                    return this._submitterPaymentService.submitCreditCardTransactions(
                        res.addCardResponse.clonedPayment.organizationID,
                        [res.addCardResponse.clonedPayment],
                        {
                            pendingPaymentLabel:
                                res.addCardResponse.clonedPayment
                                    .pendingPaymentType === "RECORDING"
                                    ? res.addCardResponse.clonedPayment.label
                                    : `${res.addCardResponse.clonedPayment.label} (${res.addCardResponse.clonedPayment.organizationID})`,
                            switchedAccountType: "CREDIT_CARD",
                            switchedAccountLabel:
                                res.addCardResponse.modalResponse.label,
                            isRecording:
                                res.addCardResponse.clonedPayment
                                    .pendingPaymentType === "RECORDING",
                            isNewCard: true,
                            hideLink:
                                res.addCardResponse.clonedPayment
                                    .pendingPaymentMethodType === "CREDIT_CARD",
                            isImmediateRetry: true,
                            isFailedLicense: res.addCardResponse.isFailedLicense
                        }
                    );
                }
            } else {
                this._savePaymentAccount = false;
                const errorObj = {
                    error: {
                        errorMessage: "Payment account not saved"
                    }
                };
                return throwError(errorObj);
            }
        })
    );

    queryParamChanges$ = this._route.queryParamMap.pipe(
        tap((queryParams: any) => {
            if (
                (!history.state.prevPage &&
                    queryParams?.get("growlRedirect")) || // click link in growl
                (!history.state.prevPage &&
                    queryParams?.get("selectedPaymentType")) // click back button
            ) {
                this.pendingPaymentsDisplayForm
                    .get("selectedPaymentType")
                    .patchValue(queryParams.get("selectedPaymentType"));
            }
        })
    );

    vm$: Observable<any> = combineLatest([
        this.pendingPaymentsDisplay$,
        this.pendingPaymentsDisplayForm$,
        this.getUserPaymentAccountTypes$,
        this.queryParamChanges$
    ]).pipe(
        map(
            ([
                pendingPaymentsDisplay,
                pendingPaymentsDisplayForm,
                getUserPaymentAccountTypes,
                queryParamChanges
            ]) => ({
                pendingPaymentsDisplay,
                pendingPaymentsDisplayForm,
                getUserPaymentAccountTypes,
                queryParamChanges
            })
        )
    );

    cacheAccessor = new Semaphore("cacheAccess", 1);

    /** View Children **/
    @ViewChild("pendingPaymentGrid")
    pendingPaymentGrid: ElementRef;
    @ViewChild("orgSelectorRow")
    orgSelectorRow: ElementRef;
    @ViewChildren("checkNumberInput")
    checkNumberInputs: QueryList<ElementRef>;
    @ViewChild("statusTemplate")
    statusTemplate: TemplateRef<any>;
    @ViewChild("itemNameTemplate")
    itemNameTemplate: TemplateRef<any>;
    @ViewChild("methodTemplate")
    methodTemplate: TemplateRef<any>;
    @ViewChild("totalAmountTemplate")
    totalAmountTemplate: TemplateRef<any>;
    @ViewChild("checkNumberTemplate")
    checkNumberTemplate: TemplateRef<any>;
    @ViewChild("memoTemplate")
    memoTemplate: TemplateRef<any>;
    @ViewChild("approveTemplate")
    approveTemplate: TemplateRef<any>;
    @ViewChild("paymentAccountTemplate")
    paymentAccountTemplate: TemplateRef<any>;
    @ViewChild("dateTemplate")
    dateTemplate: TemplateRef<any>;
    @ViewChild("orgTemplate")
    orgTemplate: TemplateRef<any>;
    @ViewChild("retryTemplate")
    retryTemplate: TemplateRef<any>;
    @ViewChild("failedReasonTemplate")
    failedReasonTemplate: TemplateRef<any>;

    /** Inputs from Pending Payment Dialog **/
    @Input() dialogOrgName: string;
    @Input() dialogOrg: SessionOrganization;
    @Input() dialogMode: boolean;

    /** Output to Pending Payment Dialog for Open Balance page **/
    @Output() overrideCount = new EventEmitter();

    constructor(
        private _pendingPaymentsNewService: PendingPaymentsNewService,
        private _pendingPaymentsDataService: PendingPaymentsDataService,
        private _submitterPaymentService: SubmitterPaymentService,
        private _paymentNavTickersService: PaymentNavTickersService,
        private _sessionService: SessionService,
        private userSettingsService: UserSettingsService,
        private _modalService: NgbModal,
        private _growlService: GrowlService,
        private _bannerService: BannerNotificationService,
        private _userorgService: UserOrgService,
        private _tourService: TourService,
        private _fb: FormBuilder,
        private _route: ActivatedRoute,
        private _router: Router,
        private _userorgSubscriptionService: UserorgSubscriptionService,
        private _submitterFeeService: SubmitterFeeService,
        private _stripeService: StripeService
    ) {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        this.defaultColumnDef = {
            resizable: true,
            sortable: true
        };

        this.autoGroupColumnDef = {
            headerName: "Item",
            cellRendererParams: {
                suppressCount: true,
                checkbox: true,
                innerRenderer: TemplateRendererComponent,
                innerRendererParams: {
                    ngTemplate: this.itemNameTemplate
                }
            },
            field: "label",
            // minWidth: 100,
            // width: 250,
            minWidth: 250,
            flex: 1,
            sortable: true,
            resizable: true,
            headerCheckboxSelection: true,
            comparator: this.packageNameComparator
        };
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
    }

    subscribeToUpdates() {
        if (!this._allOrgsIDArray) {
            return;
        }
        this._allOrgsIDArray.forEach((orgID) => {
            this._userorgSubscriptionService
                .subscribeToCountsChanged(orgID)
                .subscribe((msg: any) => {
                    let data: any = JSON.parse(msg.data);
                    switch (data.type) {
                        case "pendingPaymentsUpdated":
                            if (
                                data.userid !==
                                this._sessionService.getUsername()
                            ) {
                                this._growlService.info(
                                    "Page was updated in response to changes by user: <b>" +
                                        data.user +
                                        "</b>",
                                    "Automatic Update",
                                    { timeOut: 5000 }
                                );
                                this._refreshPendingPayments(true, false);
                                this._refreshPendingPaymentCount();
                            }
                            break;
                    }
                });
        });
    }

    get pendingPaymentsDisplayForm() {
        return this._pendingPaymentsDisplayForm.getValue();
    }

    private _orgsGood(inOrgs: Array<string>): boolean {
        let result: boolean = true;
        inOrgs.forEach((val) => {
            result = result && this._allOrgsIDArray.includes(val);
        });
        return result;
    }

    hideApproved() {
        const shouldHide = this.pendingPaymentsDisplayForm.get(
            "hideApprovedPayments"
        ).value;
        const orgIDArray: any[] =
            this.pendingPaymentsDisplayForm.get("selectedOrgOption").value ===
            "none"
                ? this._allOrgsIDArray
                : [
                      this.pendingPaymentsDisplayForm.get("selectedOrgOption")
                          .value
                  ];
        this._pendingPaymentsDataService.filterApprovedEcheckPayments(
            shouldHide,
            orgIDArray
        );
    }

    isTransactionFormValid(echeckID: string): boolean {
        return (
            this.transactionsForm.get(echeckID).valid &&
            this._isValidCheckNumber(
                this.transactionsForm.get(`${echeckID}.echeck.checkNumber`)
                    .value
            )
        );
    }

    setPendingEcheckPaymentsTour(tour: any) {
        this.pendingEcheckPaymentsTour = tour;
    }

    setApprovedEcheckChangingTour(tour: any) {
        this.approvedEcheckChangingTour = tour;
    }

    setPendingCreditCardPaymentsTour(tour: any) {
        this.pendingCreditCardPaymentsTour = tour;
    }

    showTour() {
        setTimeout(() => {
            switch (this.selectedPaymentType) {
                case "ECHECK":
                    this.approvePendingEcheckPaymentsStartTour();
                    this.approvedEcheckChangingStartTour();
                    break;
                case "CREDIT_CARD":
                    this.approvePendingCreditCardPaymentsStartTour();
                    break;
                default:
                    break;
            }
        }, 1000);
    }

    approvePendingEcheckPaymentsStartTour() {
        // show tour if not seen already
        if (
            this._tourService.shouldShowTour(
                "SHOW_TOUR_APPROVE_PENDING_ECHECK_PAYMENTS"
            ) &&
            this.selectedOrgEcheckCount > 0 &&
            this._hasUnapprovedEchecks
        ) {
            this.showTours = true;
            setTimeout(() => {
                this.pendingEcheckPaymentsTour.startTour();
            }, 500);
        }
    }

    approvedEcheckChangingStartTour() {
        if (
            this._tourService.shouldShowTour(
                "SHOW_TOUR_APPROVED_ECHECK_PAYMENT_ACCOUNT_CHANGES"
            )
        ) {
            this.showTours = true;
            setTimeout(() => {
                this.approvedEcheckChangingTour.startTour();
            }, 500);
        }
    }

    approvePendingCreditCardPaymentsStartTour() {
        // show tour if not seen already
        if (
            this._tourService.shouldShowTour(
                "SHOW_TOUR_APPROVE_PENDING_CREDIT_CARD_PAYMENTS"
            ) &&
            this.selectedOrgCreditCardCount > 0
        ) {
            this.showTours = true;
            setTimeout(() => {
                this.pendingCreditCardPaymentsTour.startTour();
            }, 500);
        }
    }

    approvePendingPaymentsTourFinished(tourSetting: string) {
        this.userSettingsService.setUserSetting(tourSetting, false);
    }

    // start ag-grid methods
    getRowId(params: GetRowIdParams) {
        if (params.data.echeck) {
            return params.data.echeck.echeckID;
        }
        if (params.data.pendingPaymentMethodType === "CREDIT_CARD") {
            return params.data.failedTransactionItemID;
        }
        // if (params.data.pendingPaymentMethodType === "ACH" && !this.showAllPaymentAccountSelector) {
        //     if (params.data.items.length && params.data.items.length === 1) {
        //         return params.data.items[0].id;
        //     } else {
        //         return `${params.data.items[0].id}.${params.data.paymentAccountID}`; // prevent duplicate row node ids
        //     }
        // }
        if (params.data.pendingPaymentMethodType === "ACH") {
            if (params.data.items.length && params.data.items.length > 1) {
                return params.data.paymentAccountID;
            } else {
                return params.data.achReferenceID; // prevent duplicate row node ids
            }
        }
    }

    getRowClass(data: any) {
        if (
            data.data.pendingPaymentMethodType === "ACH" &&
            data.data.items.length > 1
        ) {
            return "ach-group-items";
        }
    }

    getDataPath(rowItem: any) {
        return rowItem.path;
    }

    onGridSizeChanged() {
        if (this._gridApi) {
            setTimeout(() => {
                this._gridApi.sizeColumnsToFit();
            });
        }
    }

    getSelectedRowData() {
        this.selectedPendingPaymentsIDs = this._gridApi
            .getSelectedRows()
            .map((row: any) => {
                if (row.pendingPaymentMethodType === "ECHECK") {
                    return row.echeck.echeckID;
                } else if (row.pendingPaymentMethodType === "CREDIT_CARD") {
                    return row.failedTransactionItemID;
                }
                return null;
            });
        this.hasSelectedRows = !!this._gridApi.getSelectedRows().length;
        this.selectedPaymentsAreValid();
    }

    isRowSelectable(rowNode: any): boolean {
        if (rowNode.data.pendingPaymentMethodType === "ECHECK") {
            return (
                !rowNode.data.inDialogMode &&
                (rowNode.data.status === PendingPaymentStatus.APPROVE_1 ||
                    rowNode.data.status === PendingPaymentStatus.APPROVE_2 ||
                    rowNode.data.status === PendingPaymentStatus.REFUND)
            );
        } else if (rowNode.data.pendingPaymentMethodType === "CREDIT_CARD") {
            return (
                !rowNode.data.inDialogMode &&
                rowNode.data.pendingPaymentType === "RECORDING" &&
                rowNode.data.errorMessage
            );
        }
    }

    onFirstData(event: FirstDataRenderedEvent): void {
        this._gridApi?.hideOverlay();
    }

    onGridReady($event: { api: GridApi; columnApi: ColumnApi }) {
        this._gridApi = $event.api;
        this._columnApi = $event.columnApi;

        if (!this.pendingPaymentsLoaded) this._gridApi.showLoadingOverlay();

        this._gridApi.setColumnDefs([
            {
                headerName: "Organization",
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.orgTemplate
                },
                field: "organizationID",
                sortable: true,
                minWidth: 200,
                flex: 1
                //suppressSizeToFit: true
            },
            {
                headerName: "Payment Account",
                minWidth: 300,
                flex: 1,
                // width: 325,
                // suppressSizeToFit: true,
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.paymentAccountTemplate
                },
                field: "paymentAccountLabel",
                sortable: true,
                comparator: this.paymentAccountComparator
            },
            {
                headerName: "Date",
                minWidth: 100,
                // maxWidth: 100,
                width: 100,
                suppressSizeToFit: true,
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.dateTemplate
                },
                field: "referenceDate",
                sortable: true
            },
            {
                headerName: "Amount",
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.totalAmountTemplate
                },
                minWidth: 120,
                //maxWidth: 120,
                //flex: 1,
                // maxWidth: 100,
                width: 120,
                suppressSizeToFit: true,
                field: "feeTotals.total",
                sortable: true,
                comparator: this.packageNameComparator
            },
            {
                headerName: "Check #",
                colId: "checkNumber",
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.checkNumberTemplate
                },
                minWidth: 140,
                // maxWidth: 140,
                width: 140,
                suppressSizeToFit: true,
                field: "echeck.checkNumber",
                sortable: true,
                comparator: this.checkNumberComparator,
                suppressKeyboardEvent: this.suppressTabNavigation
            },
            {
                headerName: "Memo",
                colId: "memo",
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.memoTemplate
                },
                //minWidth: 90,
                //maxWidth: 90,
                width: 90,
                suppressSizeToFit: true,
                field: "echeck.memo"
            },
            {
                headerName: "Failed Reason",
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.failedReasonTemplate
                },
                field: "failedReason"
            },
            {
                headerName: "Status",
                colId: "status",
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.statusTemplate
                },
                //minWidth: 130,
                // maxWidth: 130,
                width: 140,
                suppressSizeToFit: true,
                sortable: true,
                field: "paymentStatus"
            },
            {
                headerName: this.dialogMode ? "Override" : "Approve",
                colId: "approve",
                cellRenderer: TemplateRendererComponent,
                cellRendererParams: {
                    ngTemplate: this.approveTemplate
                },
                //minWidth: 120,
                // maxWidth: 120,
                width: 140,
                suppressSizeToFit: true,
                sortable: true,
                field: "status.sortOrder"
            }
            // {
            //     headerName: this.dialogMode ? "Override" : "Retry",
            //     colId: "retry",
            //     cellRenderer: TemplateRendererComponent,
            //     cellRendererParams: {
            //         ngTemplate: this.retryTemplate
            //     },
            //     minWidth: 120,
            //     //maxWidth: 120,
            //     //width: 120,
            //     //suppressSizeToFit: true,
            //     sortable: true,
            //     field: "status.sortOrder"   //wrong?
            // }
        ]);

        if (this._waitingForGridApi) {
            this._finalizeGridSetup();
            this._waitingForGridApi = false;
        }
    }

    packageNameComparator(packageName1: string, packageName2: string) {
        return packageName1.localeCompare(packageName2, undefined, {
            numeric: true,
            sensitivity: "base"
        });
    }

    checkNumberComparator(checkNumber1: any[], checkNumber2: any[]) {
        if (!checkNumber1) {
            checkNumber1 = [""];
        }
        if (!checkNumber2) {
            checkNumber2 = [""];
        }
        return checkNumber1[0].localeCompare(checkNumber2[0], undefined, {
            numeric: true,
            sensitivity: "base"
        });
    }

    paymentAccountComparator(
        paymentAccount1: string,
        paymentAccount2: string,
        node1: any,
        node2: any
    ) {
        if (
            node1.data.pendingPaymentMethodType === "ECHECK" &&
            node1.data.isRefund
        ) {
            paymentAccount1 = "";
        }
        if (
            node2.data.pendingPaymentMethodType === "ECHECK" &&
            node2.data.isRefund
        ) {
            paymentAccount2 = "";
        }
        return paymentAccount1.localeCompare(paymentAccount2, undefined, {
            numeric: true,
            sensitivity: "base"
        });
    }

    suppressTabNavigation(params: any) {
        if (
            params.event.code === "Tab" ||
            params.event.key === "Tab" ||
            params.event.keyCode === 9 ||
            params.event.which === 9
        ) {
            params.event.preventDefault();
            return true;
        }
        return false;
    }
    // end ag-grid methods

    getSortedEcheckRowIDs() {
        this._echeckAmts = []; // clear out previous sorted echeck IDs
        this._gridApi?.forEachNodeAfterFilterAndSort((node: any) =>
            !node.data.approved || node.data.editMode
                ? this._echeckAmts.push(node.data.echeck.echeckID)
                : null
        );
    }

    focusNextEcheckNumber(event: any, echeckID: string) {
        const isTab =
            event.code === "Tab" ||
            event.key === "Tab" ||
            event.keyCode === 9 ||
            event.which === 9;
        const isShiftTab = event.shiftKey && isTab;
        let nextIndex: number;
        if (isTab && !isShiftTab) {
            nextIndex =
                this._echeckAmts.indexOf(echeckID) ===
                this._echeckAmts.length - 1
                    ? 0
                    : this._echeckAmts.indexOf(echeckID) + 1;
            setTimeout(() => {
                document.getElementById(this._echeckAmts[nextIndex])?.focus();
            });
        } else if (isShiftTab) {
            nextIndex =
                this._echeckAmts.indexOf(echeckID) === 0
                    ? this._echeckAmts.length - 1
                    : this._echeckAmts.indexOf(echeckID) - 1;
            setTimeout(() => {
                document.getElementById(this._echeckAmts[nextIndex])?.focus();
            });
        }
    }

    calculatePillStyles(pendingPayment: any) {
        if (pendingPayment.pendingPaymentMethodType === "ECHECK") {
            const echeck = this.transactionsForm.get(
                pendingPayment.echeck.echeckID
            ).value;
            if (echeck.isDelinquent) {
                return {
                    delinquentPill: true
                };
            } else if (echeck.approved) {
                return {
                    approvedPill: true
                };
            } else if (
                (!echeck.approved &&
                    !echeck.isDelinquent &&
                    echeck.requiresTwoApprovers &&
                    echeck.echeck.firstApprover &&
                    echeck.echeck.firstApprover ===
                        this._getCurrentUsername()) ||
                (!echeck.approved && !echeck.isDelinquent)
            ) {
                return {
                    needsApprovalPill: true
                };
            }
        }

        if (
            pendingPayment.pendingPaymentMethodType === "ACH" ||
            pendingPayment.isChild
        ) {
            return {
                achPill: true
            };
        }

        if (pendingPayment.pendingPaymentMethodType === "CREDIT_CARD") {
            if (pendingPayment.isDelinquent || pendingPayment.errorMessage) {
                return {
                    delinquentPill: true
                };
            } else {
                return {
                    readyPill: true
                };
            }
        }
    }

    calculatePillText(pendingPayment: any) {
        if (pendingPayment.pendingPaymentMethodType === "ECHECK") {
            const echeck = this.transactionsForm.get(
                pendingPayment.echeck.echeckID
            ).value;
            if (echeck.isDelinquent) {
                return "Past Due";
            }

            if (echeck.approved) {
                return "Approved";
            }

            if (
                !echeck.approved &&
                !echeck.isDelinquent &&
                echeck.requiresTwoApprovers &&
                echeck.echeck.firstApprover &&
                echeck.echeck.firstApprover === this._getCurrentUsername()
            ) {
                return "Needs 2nd Approval";
            }

            if (!echeck.approved && !echeck.isDelinquent) {
                return "Needs Approval";
            }
        }

        if (
            pendingPayment.pendingPaymentMethodType === "ACH" ||
            pendingPayment.isChild
        ) {
            return "Ready";
        }

        if (pendingPayment.pendingPaymentMethodType === "CREDIT_CARD") {
            if (pendingPayment.isDelinquent || pendingPayment.errorMessage)
                return "Past Due";
            else return "Ready";
        }
    }

    calculateCheckNumberClass(echeckID: string, approved: boolean) {
        const editMode =
            this.transactionsForm.get(`${echeckID}.status`).value ===
            PendingPaymentStatus.EDIT_PAYMENT;
        const waiting =
            this.transactionsForm.get(`${echeckID}.status`).value ===
            PendingPaymentStatus.WAITING;
        const isIE = window.bowser.name.search(/Internet Explorer/i) >= 0;
        if ((approved && !editMode) || (!approved && waiting)) {
            return {
                "check-number-approved": true,
                "check-number-ie": isIE
            };
        }
        if (approved && editMode) {
            return {
                "edit-check-number": true,
                "check-number-ie": isIE
            };
        }
        return {
            "check-number-not-approved": true,
            "check-number-ie": isIE
        };
    }

    setVerticalScroll() {
        return this._pendingPaymentsLength > 12 ? "vertical-scroll" : null;
    }

    goToManageAccounts() {
        const selectedOrgID =
            this.pendingPaymentsDisplayForm.get("selectedOrgOption").value;
        const url: string = `/sf/ui/submitter/payments/manage/${selectedOrgID}`;
        window.open(url, "_blank");
        setTimeout(() => this._focusAway());
    }

    paymentItemClicked(packageID: string) {
        const url: string = `/sf/ui/submitter/package/${packageID}/details`;
        window.open(url, "_blank");
        setTimeout(() => this._focusAway());
    }

    handleMiddleClick(event: any, packageID: string) {
        if (event.button === 1) {
            this.paymentItemClicked(packageID);
        }
        setTimeout(() => this._focusAway());
    }

    licenseFeeClicked(orgID: string, licenseLabel: string) {
        let licenseType: string;
        let url: string;
        if (this._sessionService.isSuperUser()) {
            if (licenseLabel.includes("Document Builder")) {
                licenseType = "document-builder";
            } else if (licenseLabel.includes("eSign Events")) {
                licenseType = "sign-event";
            } else {
                licenseType = "submitter";
            }
            url = `admin/organization/${orgID}/${licenseType}/license`;
        } else {
            if (licenseLabel.includes("Document Builder")) {
                licenseType = "document-builder";
            } else if (licenseLabel.includes("eSign Events")) {
                licenseType = "sign-event";
            } else {
                licenseType = "e-record";
            }
            url = `submitter/organization/${orgID}/${licenseType}/license`;
        }
        window.open(url, "_blank");
        setTimeout(() => this._focusAway());
    }

    handleLicenseFeeMiddleClick(
        event: any,
        orgID: string,
        licenseLabel: string
    ) {
        if (event.button === 1) {
            this.licenseFeeClicked(orgID, licenseLabel);
        }
        setTimeout(() => this._focusAway());
    }

    clickFeeDetails(pendingPayment: any) {
        const modalRef = this._modalService.open(
            PendingPaymentsFeeDetailDialogComponent,
            {
                backdrop: "static",
                size: "lg"
            }
        );

        const modalInstance = modalRef.componentInstance;
        modalInstance.submitterName = this.dialogMode
            ? `${this.dialogOrgName} (${pendingPayment.organizationID})`
            : `${this._sessionService.getOrganizationName(
                  pendingPayment.organizationID
              )} (${pendingPayment.organizationID})`;
        modalInstance.recipientName = pendingPayment.isChild
            ? pendingPayment.recipient
            : pendingPayment.items[0].recipient;
        modalInstance.packageName = pendingPayment.label;
        modalInstance.date =
            pendingPayment.pendingPaymentMethodType === "ECHECK"
                ? dayjs(pendingPayment.referenceDate).format("MM/DD/YYYY")
                : dayjs(pendingPayment.date).format("MM/DD/YYYY");
        modalInstance.packageID = pendingPayment.packageID;
        modalInstance.recordingFees = +pendingPayment.feeTotals.recording;
        modalInstance.salesTax = +pendingPayment.feeTotals.salesTax;
        modalInstance.submissionFees = +pendingPayment.feeTotals.submission;
        modalInstance.tax = +pendingPayment.feeTotals.tax;
        modalInstance.surchargePercentage = pendingPayment.surchargePercentage;
        modalInstance.surchargeAmount = +pendingPayment.feeTotals.surcharge;
        modalInstance.total = +pendingPayment.feeTotals.total;
        modalInstance.otherFees = +pendingPayment.feeTotals.other;
        modalInstance.paymentAccountName = pendingPayment.paymentAccountLabel;
        modalInstance.hasSubmitterPackageViewerPermission =
            this.hasSubmitterPackageViewerPermission(
                pendingPayment.organizationID
            );
        modalInstance.mailFee =
            pendingPayment.feeTotals.mail > 0
                ? pendingPayment.feeTotals.mail
                : null;

        modalRef.result.then(
            () => {
                setTimeout(() => this._focusAway()); // set focus away from fees
            },
            () => {
                setTimeout(() => this._focusAway()); // set focus away from fees
            }
        );
    }

    clickCreditCardFee(pendingPayment: any) {
        const modalRef = this._modalService.open(
            PastDueCreditCardPaymentDialogComponent,
            {
                backdrop: "static",
                size: "lg"
            }
        );

        if (pendingPayment.path) {
            // to ensure we are using what is on the form
            pendingPayment = this.transactionsForm.get(
                pendingPayment.failedTransactionItemID
            ).value;
        }

        const isFailedLicense = pendingPayment.pendingPaymentType === "LICENSE";

        const modalInstance = modalRef.componentInstance;
        modalInstance.packageID = pendingPayment.packageID;
        modalInstance.feeTotal = pendingPayment.feeTotals.total;
        modalInstance.creditCardLabel = pendingPayment.paymentAccountLabel;
        modalInstance.selectedOrgID =
            this.pendingPaymentsDisplayForm.get("selectedOrgOption").value;
        modalInstance.hasManageAccountPermission =
            this._sessionService.hasPermission(
                "organization_accounting",
                pendingPayment.organizationID
            );
        modalInstance.isFailedLicense = isFailedLicense;

        modalRef.result.then(
            (retryPayment: boolean) => {
                if (retryPayment) {
                    if (!pendingPayment.paymentAccountID) {
                        this._growlService.error(
                            `Payment retry failed for type: ${pendingPayment.pendingPaymentType.toLowerCase()} - ${
                                pendingPayment.items[0].label
                            }. No payment account selected. Please select or add a credit card payment account.`,
                            null,
                            { disableTimeOut: true }
                        );
                        setTimeout(() => this._focusAway()); // set focus away
                        return;
                    } else {
                        let clonedPayment = clone(pendingPayment);
                        this._savePaymentAccount = true;
                        this._gridApi?.showLoadingOverlay();
                        iif(
                            () => isFailedLicense,
                            of(true),
                            iif(
                                () => !!clonedPayment.packageID,
                                this._pendingPaymentsNewService.setPackagePaymentAccounts(
                                    clonedPayment.packageID,
                                    clonedPayment.paymentAccountID,
                                    clonedPayment.editableCategories
                                ),
                                of(true)
                            )
                        )
                            .pipe(
                                switchMap((success: boolean) => {
                                    if (success) {
                                        if (!isFailedLicense) {
                                            this._growlService.success(
                                                "Payment account saved"
                                            );
                                            this._savePaymentAccount = false;
                                        }
                                        this._retryPayment = true;
                                        clonedPayment.isFailedLicense =
                                            isFailedLicense;
                                        return this._submitterPaymentService.submitCreditCardTransactions(
                                            clonedPayment.organizationID,
                                            [clonedPayment]
                                        );
                                    } else {
                                        this._savePaymentAccount = false;
                                        const errorObj = {
                                            error: {
                                                errorMessage:
                                                    "Payment account not saved."
                                            }
                                        };
                                        return throwError(errorObj);
                                    }
                                })
                            )
                            .subscribe(
                                () => {
                                    this._retryPayment = false;
                                    if (this._overlayPaymentType === "eCheck") {
                                        this._overlayPaymentType =
                                            "credit card";
                                    }
                                    this._refreshPendingPayments(true);
                                    this._refreshPendingPaymentCount();
                                    this._refreshBanner();
                                    this._growlService.success(
                                        "Payment retry successful"
                                    );
                                    setTimeout(() => this._focusAway()); // set focus away
                                    if (isFailedLicense) {
                                        let ccLabel: string;
                                        pendingPayment.orgCCAccounts.forEach(
                                            (account: any) => {
                                                if (
                                                    account.id ==
                                                    pendingPayment.paymentAccountID
                                                )
                                                    ccLabel = account.label;
                                            }
                                        );
                                        this._growlService.success(
                                            "Default payment option for your license fee has been set to " +
                                                ccLabel
                                        );
                                    }
                                },
                                (err: any) => {
                                    this._retryPayment = false;
                                    this._refreshPendingPayments(true);
                                    if (
                                        err.error.errorMessage ===
                                        "Payment is not currently editable."
                                    ) {
                                        this._growlService.error(
                                            `Payment retry failed for type: ${clonedPayment.pendingPaymentType.toLowerCase()} - ${
                                                clonedPayment.items[0].label
                                            }, ${
                                                err.error.errorMessage
                                            } Please verify that the payment still needs to be retried.`,
                                            null,
                                            { disableTimeOut: true }
                                        );
                                    } else {
                                        this._growlService.error(
                                            `Payment retry failed for type: ${clonedPayment.pendingPaymentType.toLowerCase()} - ${
                                                clonedPayment.items[0].label
                                            }. ${err.error.errorMessage}`,
                                            null,
                                            { disableTimeOut: true }
                                        );
                                    }
                                    setTimeout(() => this._focusAway()); // set focus away
                                }
                            );
                    }
                } else {
                    setTimeout(() => this._focusAway()); // set focus away
                }
            },
            () => {
                setTimeout(() => this._focusAway()); // set focus away from fees
            }
        );
    }

    openBulkCCRetryDialog(pendingCCPayments: any[]) {
        const modalRef = this._modalService.open(
            BulkCreditCardRetryDialogComponent,
            {
                backdrop: "static",
                size: "lg"
            }
        );

        modalRef.result.then((retryPayments: boolean) => {
            if (retryPayments) {
                this._submitPayments(pendingCCPayments);
                setTimeout(() => this._focusAway());
            } else {
                setTimeout(() => this._focusAway());
            }
        });
    }

    clickMemo(echeckID: string) {
        const modalRef = this._modalService.open(
            EditEcheckMemoDialogComponent,
            {
                backdrop: "static",
                windowClass: "edit-address-dialog"
            }
        );

        const modalInstance = modalRef.componentInstance;
        const editMode = this.transactionsForm.get(
            `${echeckID}.editMode`
        ).value;

        modalInstance.memo = this.transactionsForm.get(
            `${echeckID}.echeck.memo`
        ).value;
        modalInstance.editMode = editMode;

        modalRef.result.then(
            (memo: string) => {
                this.transactionsForm
                    .get(`${echeckID}.echeck.memo`)
                    .patchValue(memo);
                if (!editMode) {
                    this.saveCheckInfo(
                        this.transactionsForm.get(echeckID).value
                    );
                    this._pendingPaymentsDataService.updateEcheckMemo(
                        echeckID,
                        this.transactionsForm.get(`${echeckID}.echeck.memo`)
                            .value
                    ); // update the echeck in the data store cache
                }
                setTimeout(() => this._focusAway()); // set focus away from memo icon
            },
            () => {
                setTimeout(() => this._focusAway()); // set focus away from memo icon
            }
        );
    }

    addNewCreditCard(
        orgID: string,
        failedTransactionID?: string,
        isRecording?: boolean
    ) {
        if (this._sessionService.isLoggedInAs()) {
            this._growlService.error(
                "A Simplifile employee cannot add or edit payment accounts while impersonating a user."
            );
            return;
        }

        const modalRef = this._modalService.open(
            AddEditPaymentAccountDialogComponent,
            {
                backdrop: "static",
                windowClass: "edit-address-dialog"
            }
        );

        const modalInstance = modalRef.componentInstance;
        modalInstance.orgId = orgID;
        modalInstance.addCCFromPendingPayments = true;
        modalInstance.isRecording = isRecording;

        modalRef.result.then(
            (response) => {
                let clonedPayment = clone(
                    this.transactionsForm.get(`${failedTransactionID}`).value
                );
                const isFailedLicense =
                    clonedPayment.pendingPaymentType === "LICENSE";
                const isRecordingCCNotDebit =
                    clonedPayment.pendingPaymentType === "RECORDING" &&
                    response.cardType !== "DEBIT";
                if (isRecordingCCNotDebit) {
                    const expDate = dayjs(
                        `${response.expirationDate.expMonth}/1/${response.expirationDate.expYear}`
                    ).format("MM/YY");
                    const newPaymentAccount = {
                        label:
                            response.cardType !== "DEBIT"
                                ? `${response.label} (Exp. ${expDate})`
                                : `${response.label} (Debit Exp. ${expDate})`,
                        type: response.cardType
                    };
                    this._confirmSurcharge(
                        clonedPayment,
                        response.id,
                        false,
                        newPaymentAccount,
                        clonedPayment.organizationID,
                        this._getTotalWithoutSurcharge(clonedPayment.feeTotals),
                        true
                    )
                        .pipe(
                            tap((res: any) => {
                                if (res.surchargeConfirmed) {
                                    this._newCreditCardAdded.next({
                                        clonedPayment: clonedPayment,
                                        isFailedLicense: isFailedLicense,
                                        modalResponse: response
                                    });
                                    this._addNewCardAddedSubscription(
                                        clonedPayment
                                    );
                                } else {
                                    this._refreshPendingPayments(true);
                                }
                            })
                        )
                        .subscribe();
                } else {
                    this._newCreditCardAdded.next({
                        clonedPayment: clonedPayment,
                        isFailedLicense: isFailedLicense,
                        modalResponse: response
                    });
                    this._addNewCardAddedSubscription(clonedPayment);
                }
            },
            () => {
                setTimeout(() => this._focusAway()); // set focus away from CC account selector
            }
        );
    }

    clickCheckNumber(echeckID: string) {
        let isIE = window.bowser.name.search(/Internet Explorer/i) >= 0;
        if (isIE) {
            let clickedCheckNumber: ElementRef = this.checkNumberInputs.find(
                (echeck: ElementRef) => echeck.nativeElement.id === echeckID
            );
            clickedCheckNumber.nativeElement.blur();
            clickedCheckNumber.nativeElement.focus();
        }
    }

    getLicenseFeeTooltip(orgID: string) {
        const orgName = this.dialogMode
            ? this.dialogOrgName
            : this._sessionService.getOrganizationName(orgID);
        return `Click to view license details for ${orgName} (${orgID})`;
    }

    getPaymentAccountTooltip(pendingPayment: any) {
        const orgName = this.dialogMode
            ? this.dialogOrgName
            : this._sessionService.getOrganizationName(
                  pendingPayment.organizationID
              );
        const isArchived = pendingPayment.packageArchived
            ? " Package is archived"
            : "";

        return `${orgName} (${pendingPayment.organizationID})${isArchived}`;
    }

    getOrganizationName(orgID: string) {
        const orgName = this.dialogMode
            ? this.dialogOrgName
            : this._sessionService.getOrganizationName(orgID);
        return `${orgName} (${orgID})`;
    }

    onSelect() {
        setTimeout(() => this._focusAway()); // set focus away from selector
    }

    onOrgSelect() {
        setTimeout(() => this.orgSelectorRow.nativeElement.focus()); // set focus away from selector
    }

    getMemoTooltip(echeckID: string) {
        return this.transactionsForm.get(`${echeckID}.echeck.memo`).value;
    }

    hasEcheckMemo(echeckID: string) {
        return this.transactionsForm.get(`${echeckID}.echeck.memo`).valid;
    }

    async saveCheckInfo(pendingEcheck: any) {
        if (!pendingEcheck) {
            return;
        }

        //obtain lock for accessing cached data; once obtained use current modified date
        const lock = await this.cacheAccessor.acquire();
        let upToDateEcheck: any =
            this._pendingPaymentsDataService.getUpdatedEcheck(pendingEcheck);
        pendingEcheck.echeck.modifiedDate = upToDateEcheck.echeck.modifiedDate;

        let pArray: any[] = [];
        pArray = this._addCheckInfo(pendingEcheck, pArray);

        this._submitterPaymentService.upsertECheckInfoNew(pArray).subscribe(
            (updatedEchecks: any[]) => {
                const editMode = this.transactionsForm.get(
                    `${pendingEcheck.echeck.echeckID}.editMode`
                ).value;
                if (editMode) {
                    this.transactionsForm
                        .get(`${pendingEcheck.echeck.echeckID}.editMode`)
                        .patchValue(false);
                    this.transactionsForm
                        .get(`${pendingEcheck.echeck.echeckID}.status`)
                        .patchValue(PendingPaymentStatus.APPROVED_NOT_PRINTED);
                }
                //PS-19178: update modified date on echecks in the form, so it sends the correct modified date if the echeck
                //is modified again by the user
                updatedEchecks.forEach((echeck: any) => {
                    this.transactionsForm
                        .get(
                            `${pendingEcheck.echeck.echeckID}.echeck.modifiedDate`
                        )
                        .patchValue(echeck.modifiedDate);
                    //this updates the cache so if the grid is refreshed (which it does), it gets the correct modified date
                    this._pendingPaymentsDataService.updateModifiedDate(echeck);
                });
                lock.release();
            },
            () => {
                const editMode = this.transactionsForm.get(
                    `${pendingEcheck.echeck.checkNumber}.editMode`
                )?.value;
                if (editMode) {
                    this.transactionsForm
                        .get(`${pendingEcheck.echeck.checkNumber}.editMode`)
                        .patchValue(false);
                    pendingEcheck.status =
                        PendingPaymentStatus.PAYMENT_PROCESSING;
                    this._growlService.warning(
                        "Payment is being processed and can no longer be edited."
                    );
                }

                this._refreshPendingPayments(true);
                lock.release();
            }
        );
    }

    updateEcheckNumber(pendingEcheck: any) {
        if (
            !pendingEcheck.echeck.checkNumber ||
            !this._isValidCheckNumber(pendingEcheck.echeck.checkNumber)
        ) {
            return;
        }

        this.saveCheckInfo(pendingEcheck);
        const splitCheckNumber: any[] = this._splitCheckNumber(
            pendingEcheck.echeck.checkNumber
        );

        if (!isNaN(splitCheckNumber[1])) {
            this.suggestedCheckNumbers = [
                `${splitCheckNumber[0]}${++splitCheckNumber[1]}`
            ];
        }
        // update the echeck in the data store cache
        this._pendingPaymentsDataService.updateEcheckNumber(
            pendingEcheck.echeck.echeckID,
            pendingEcheck.echeck.checkNumber
        );
    }

    submitSelectedPendingPayments() {
        this._multiplePaymentsSelected =
            this.selectedPendingPaymentsIDs.length > 1;
        this.selectedEcheckPendingPayments = [];
        this.selectedCreditCardPendingPayments = [];
        if (this._sessionService.isLoggedInAs()) {
            this._growlService.error(
                "You cannot perform this action while impersonating a user."
            );
        } else {
            this.selectedPendingPaymentsIDs.forEach((paymentID: string) => {
                const pendingPayment =
                    this.transactionsForm.get(paymentID).value;
                if (pendingPayment.pendingPaymentMethodType === "ECHECK") {
                    this.selectedEcheckPendingPayments.push(pendingPayment);
                } else if (
                    pendingPayment.pendingPaymentMethodType === "CREDIT_CARD"
                ) {
                    this.selectedCreditCardPendingPayments.push(pendingPayment);
                }
            });
            if (this.selectedEcheckPendingPayments.length > 0) {
                let mailRefunds: any[] = [];
                this.selectedEcheckPendingPayments.forEach((payment: any) => {
                    if (payment.isRefund && payment.targetAccountID === "CHECK")
                        mailRefunds.push(payment);
                });
                if (mailRefunds.length > 0) {
                    const modalRef = this._modalService.open(
                        EcheckRefundAddressConfirmationDialogComponent,
                        {
                            backdrop: "static",
                            keyboard: false
                        }
                    );
                    const modalInstance = modalRef.componentInstance;

                    modalInstance.payments = mailRefunds;

                    modalRef.result.then(
                        (confirmed: boolean) => {
                            if (confirmed)
                                this._submitValidEcheckPayments(
                                    this.selectedEcheckPendingPayments
                                );
                        },
                        () => {
                            //do nothing
                        }
                    );
                } else {
                    this._submitValidEcheckPayments(
                        this.selectedEcheckPendingPayments
                    );
                }
            }
            if (this.selectedCreditCardPendingPayments.length === 1) {
                this._submitPayments(this.selectedCreditCardPendingPayments);
            } else if (this.selectedCreditCardPendingPayments.length > 1) {
                this.openBulkCCRetryDialog(
                    this.selectedCreditCardPendingPayments
                );
            }
        }
        this._gridApi.deselectAll();
    }

    paymentButtonClicked(payment: any) {
        const pendingPayment = this.transactionsForm.get(
            payment.echeck.echeckID
        ).value;
        const errorGrowlText: string =
            "You cannot perform this action while impersonating a user.";

        if (pendingPayment.pendingPaymentMethodType === "ECHECK") {
            switch (pendingPayment.status) {
                case PendingPaymentStatus.APPROVE_1:
                case PendingPaymentStatus.APPROVE_2:
                case PendingPaymentStatus.REFUND:
                    if (this._sessionService.isLoggedInAs()) {
                        this._growlService.error(errorGrowlText);
                    } else {
                        if (
                            pendingPayment.isRefund &&
                            pendingPayment.targetAccountID === "CHECK"
                        ) {
                            const modalRef = this._modalService.open(
                                EcheckRefundAddressConfirmationDialogComponent,
                                {
                                    backdrop: "static",
                                    keyboard: false
                                }
                            );
                            const modalInstance = modalRef.componentInstance;

                            modalInstance.payments = [pendingPayment];

                            modalRef.result.then(
                                (confirmed: boolean) => {
                                    if (confirmed)
                                        this.submitEcheckPayment(
                                            pendingPayment
                                        );
                                },
                                () => {
                                    //do nothing
                                }
                            );
                        } else {
                            this.submitEcheckPayment(pendingPayment);
                        }
                    }
                    break;
                case PendingPaymentStatus.APPROVED_NOT_PRINTED:
                    this.editPaymentClicked(pendingPayment);
                    break;
                case PendingPaymentStatus.EDIT_PAYMENT:
                    this.saveEditedPaymentClicked(pendingPayment);
                    break;
                default:
                    break;
            }
        }
        if (pendingPayment.pendingPaymentMethodType === "CREDIT_CARD") {
            if (this._sessionService.isLoggedInAs()) {
                this._growlService.error(errorGrowlText);
            } else {
                this.clickCreditCardFee(pendingPayment);
            }
        }
    }

    async submitEcheckPayment(echeckPayment: any) {
        //obtain lock for accessing cached data; once obtained use current modified date
        const lock = await this.cacheAccessor.acquire();
        let upToDateEcheck: any =
            this._pendingPaymentsDataService.getUpdatedEcheck(echeckPayment);
        echeckPayment.echeck.modifiedDate = upToDateEcheck.echeck.modifiedDate;
        //pass the lock down for unlocking when async processes are complete
        this._submitValidEcheckPayments([echeckPayment], lock);
        this._gridApi.deselectAll();
    }

    editPaymentClicked(echeckPayment: any) {
        if (
            echeckPayment.status === PendingPaymentStatus.APPROVED_NOT_PRINTED
        ) {
            this._fetchEcheckPrintedStatus(echeckPayment);
        }
    }

    saveEditedPaymentClicked(echeckPayment: any) {
        if (echeckPayment.status === PendingPaymentStatus.EDIT_PAYMENT) {
            if (!this._isValidCheckNumber(echeckPayment.echeck.checkNumber)) {
                return this._growlService.error("Invalid Check Number");
            }
            const clonedPayments = clone([echeckPayment]);
            clonedPayments.forEach((pendingPayment) => {
                delete pendingPayment.isChild;
                delete pendingPayment.label;
                delete pendingPayment.packageID;
                delete pendingPayment.paymentStatus;
                delete pendingPayment.status;
                delete pendingPayment.editMode;
                delete pendingPayment.refundAccounts;
                delete pendingPayment.paymentMethod;
            });
            this._submitterPaymentService
                .findDuplicateCheckNumbers(clonedPayments)
                .subscribe((results) => {
                    if (results && Object.keys(results).length > 0) {
                        this._showDuplicateCheckDialog(results, [
                            echeckPayment
                        ]);
                    } else {
                        this.updateEcheckNumber(echeckPayment);
                        this.saveCheckInfo(echeckPayment);
                        this.transactionsForm
                            .get(`${echeckPayment.echeck.echeckID}.status`)
                            .patchValue(
                                PendingPaymentStatus.APPROVED_NOT_PRINTED
                            );
                        echeckPayment = this.transactionsForm.get(
                            echeckPayment.echeck.echeckID
                        ).value;
                        this._refreshGrid(echeckPayment, false);
                    }
                });
        }
    }

    getTooltip(echeckID: string, column: string, isACH?: boolean) {
        switch (column) {
            case "approve":
                if (
                    this.isTransactionFormValid(echeckID) ||
                    this.transactionsForm.get(`${echeckID}`).value.isRefund
                ) {
                    return this.transactionsForm.get(`${echeckID}.status`).value
                        .tooltip;
                } else if (
                    !this.transactionsForm.get(`${echeckID}.echeck.checkNumber`)
                        .value
                ) {
                    return "Check number missing";
                } else if (
                    !this._isValidCheckNumber(
                        this.transactionsForm.get(
                            `${echeckID}.echeck.checkNumber`
                        ).value
                    )
                ) {
                    return "Invalid check number";
                } else {
                    return "Memo missing";
                }
            case "status":
                if (isACH) {
                    return "Payment is ready for ACH processing";
                }
                break;
            default:
                break;
        }
    }

    disableButton(pendingPayment: any): boolean {
        //get current form value because it will have updated info, like selected payment account, etc.
        const formPayment = this.transactionsForm.get(
            pendingPayment.echeck.echeckID
        ).value;
        if (!formPayment.status) {
            return true;
        } else if (formPayment.isRefund) {
            return formPayment.targetAccountID
                ? !formPayment.targetAccountID.length
                : true;
        } else {
            return (
                !this.isTransactionFormValid(formPayment.echeck.echeckID) ||
                !formPayment.status.paymentButtonEnabled
            );
        }
    }

    selectedPaymentsAreValid() {
        if (this.hasSelectedRows) {
            this.selectedPendingPaymentsIDs.forEach((paymentID: string) => {
                const pendingPayment =
                    this.transactionsForm.get(paymentID).value;
                if (pendingPayment.isRefund) {
                    this.selectedPaymentsValid =
                        !!pendingPayment.targetAccountID.length;
                } else {
                    if (pendingPayment.pendingPaymentMethodType === "ECHECK") {
                        this.selectedPaymentsValid =
                            this.isTransactionFormValid(
                                pendingPayment.echeck.echeckID
                            );
                    } else if (
                        pendingPayment.pendingPaymentMethodType ===
                        "CREDIT_CARD"
                    ) {
                        this.selectedPaymentsValid = this.transactionsForm.get(
                            pendingPayment.failedTransactionItemID
                        ).valid;
                    }
                }
            });
        }
    }

    getApprovedTooltip() {
        if (!this.hasSelectedRows || !this.selectedPaymentsValid) {
            return "Please select payments to approve";
        } else {
            return null;
        }
    }

    getManagePaymentAccountsTooltip() {
        if (
            this.pendingPaymentsDisplayForm.get("selectedOrgOption").value ===
            "none"
        ) {
            return "Please select a specific organization";
        } else if (!this.hasManageAccountPermission()) {
            return "You do not have permission to manage the payment accounts for this organization";
        } else {
            return "Click here to add, edit, or delete payment accounts for the selected organization";
        }
    }

    hasManageAccountPermission(): boolean {
        const selectedOrgID =
            this.pendingPaymentsDisplayForm.get("selectedOrgOption").value;
        return selectedOrgID === "none"
            ? false
            : this._sessionService.hasPermission(
                  "organization_accounting",
                  selectedOrgID
              );
    }

    hasSubmitterPackageViewerPermission(orgID: string): boolean {
        return !orgID
            ? false
            : this._sessionService.hasPermission(
                  "submitter_package_viewer",
                  orgID
              );
    }

    suggestedCheckNumber = (suggestedCheckNumbers$: Observable<string>) => {
        return suggestedCheckNumbers$.pipe(
            debounceTime(200),
            distinctUntilChanged(),
            map((checkNum) => (checkNum ? this.suggestedCheckNumbers : []))
        );
    };

    getQAATestingID(pendingPayment: any): string {
        if (pendingPayment.pendingPaymentMethodType === "ECHECK") {
            return `${pendingPayment.paymentAccountID}-ECHECK-${pendingPayment.echeck.echeckID}`;
        } else if (pendingPayment.pendingPaymentMethodType === "CREDIT_CARD") {
            return `${pendingPayment.paymentAccountID}-CREDIT_CARD-${pendingPayment.failedTransactionItemID}`;
        } else if (pendingPayment.pendingPaymentMethodType === "ACH") {
            if (pendingPayment.items.length > 1) {
                return `${pendingPayment.paymentAccountID}-ACH-Parent`;
            } else if (pendingPayment.isChild) {
                return `${pendingPayment.paymentAccountID}-ACH-Child-${pendingPayment.achReferenceID}`;
            } else {
                return `${pendingPayment.paymentAccountID}-ACH-${pendingPayment.achReferenceID}`;
            }
        }
    }

    filterAccounts(pendingPayment: any): any[] {
        let allowedPaymentAccounts: any[] = [];
        pendingPayment.orgPaymentAccounts.forEach((account: any) => {
            if (account.type === "CREDIT_CARD") {
                if (pendingPayment.ccAllowed) {
                    allowedPaymentAccounts.push(account);
                } else {
                    if (pendingPayment.pendingPaymentType !== "RECORDING") {
                        allowedPaymentAccounts.push(account);
                    }
                }
            } else {
                allowedPaymentAccounts.push(account);
            }
        });
        return allowedPaymentAccounts;
    }

    showPaymentAccountSelector(payment: any): boolean {
        //define reasons for not showing the payment selector
        if (payment.inDialogMode) return false;
        if (payment.packageArchived) return false;
        if (
            payment.isChild &&
            ((payment.pendingPaymentType === "LICENSE" &&
                !payment.contractID) ||
                payment.pendingPaymentType === "DOCUMENT_BUILDER" ||
                payment.pendingPaymentType === "OTHER")
        )
            return false;
        if (payment.isChild) {
            //expanded ACH payments
            if (
                payment.isRefund ||
                payment.pendingPaymentType === "RETRY" ||
                payment.pendingPaymentType === "RETURN_FEE"
            )
                return false;
        }
        switch (payment.pendingPaymentMethodType) {
            case "ECHECK":
                if (!payment.isRefund) {
                    if (
                        payment.pendingPaymentType === "DOCUMENT_BUILDER" ||
                        payment.pendingPaymentType === "OTHER" ||
                        (payment.pendingPaymentType === "LICENSE" &&
                            !payment.contractID)
                    )
                        return false;
                }
                break;
            case "ACH":
                if (payment.items.length > 1 || payment.isRefund) return false;
                if (
                    payment.items.length === 1 &&
                    ((payment.pendingPaymentType === "LICENSE" &&
                        !payment.contractID) ||
                        payment.pendingPaymentType === "DOCUMENT_BUILDER" ||
                        payment.pendingPaymentType === "OTHER")
                )
                    return false;
                if (
                    payment.pendingPaymentType === "RETRY" ||
                    payment.pendingPaymentType === "RETURN_FEE"
                )
                    return false;
                break;
            case "CREDIT_CARD":
                if (
                    payment.pendingPaymentType === "LICENSE" &&
                    !payment.contractID
                )
                    return false;
                if (
                    payment.pendingPaymentType === "RETRY" ||
                    payment.pendingPaymentType === "RETURN_FEE"
                )
                    return false;
                break;
        }

        //default
        return true;
    }

    getPaymentAccountFormControlName(payment: any): string {
        if (
            payment.isRefund &&
            payment.pendingPaymentMethodType === "ECHECK" &&
            !payment.inDialogMode
        )
            return "targetAccountID";

        //default
        return "paymentAccountID";
    }

    getPaymentAccountPlaceholder(payment: any): string {
        if (
            payment.isRefund &&
            payment.pendingPaymentMethodType === "ECHECK" &&
            !payment.inDialogMode
        )
            return "Send Refund to...";

        //default
        return "";
    }

    getPaymentAccountSelectorAccounts(payment: any): any {
        if (
            payment.isRefund &&
            payment.pendingPaymentMethodType === "ECHECK" &&
            !payment.inDialogMode
        )
            return payment.refundAccounts;

        //default
        return this.filterAccounts(payment);
    }

    getPaymentAccountGroupName(payment: any): string {
        switch (payment.pendingPaymentMethodType) {
            case "ECHECK":
                return payment.echeck.echeckID;
            case "ACH":
                return payment.achReferenceID;
            case "CREDIT_CARD":
                return payment.failedTransactionItemID;
        }
        if (!payment.pendingPaymentMethodType) {
            if (payment.paymentMethod === "ACH") return payment.achReferenceID;
        }
    }

    formatAmount(amt: number): string {
        let result: string = "$";
        if (amt >= 0) return result + amt;
        else {
            amt = Math.abs(amt);
            return "(" + result + amt.toFixed(2) + ")";
        }
    }

    /** Private Methods **/
    private _focusAway() {
        this.pendingPaymentGrid.nativeElement.focus();
    }

    private _updateOverrideCount(pendingPayments: any) {
        pendingPayments = Object.values(pendingPayments);
        let overrideCount = 0;
        pendingPayments.forEach((pendingPayment: any) => {
            if (
                pendingPayment.pendingPaymentMethodType == "ECHECK" &&
                pendingPayment.echeck.overrideCollections &&
                !pendingPayment.approved
            ) {
                overrideCount++;
            }
        });
        this.overrideCount.emit(overrideCount);
    }

    private _fetchEcheckPrintedStatus(echeckPayment: any) {
        this.transactionsForm
            .get(`${echeckPayment.echeck.echeckID}.status`)
            .patchValue(PendingPaymentStatus.PROCESSING);
        this._submitterPaymentService
            .checkEcheckPrintedStatus(
                this._allOrgsIDArray,
                echeckPayment.echeck.echeckID
            )
            .subscribe((result: any) => {
                echeckPayment.echeck.printed = !!result;
                echeckPayment.echeck.checkedPrintStatus = true;
                if (echeckPayment.echeck.printed) {
                    this._growlService.warning(
                        "Payment is being processed and can no longer be edited."
                    );
                    this.transactionsForm
                        .get(`${echeckPayment.echeck.echeckID}.editMode`)
                        .patchValue(false);
                    this.transactionsForm
                        .get(`${echeckPayment.echeck.echeckID}.status`)
                        .patchValue(PendingPaymentStatus.PAYMENT_PROCESSING);
                } else {
                    this.transactionsForm
                        .get(`${echeckPayment.echeck.echeckID}.editMode`)
                        .patchValue(true);
                    this.transactionsForm
                        .get(`${echeckPayment.echeck.echeckID}.status`)
                        .patchValue(PendingPaymentStatus.EDIT_PAYMENT);
                }
                this._refreshGrid(
                    this.transactionsForm.get(
                        `${echeckPayment.echeck.echeckID}`
                    ).value,
                    false
                );
            });
    }

    private _refreshGrid(payment: any, sort: boolean) {
        this._gridApi.getRowNode(payment.echeck.echeckID).setData(payment);
        this._gridApi.redrawRows();
        if (sort) {
            this._gridApi.refreshClientSideRowModel("sort");
        }
    }

    private _refreshPaymentStatus(pendingPayments: any[]) {
        pendingPayments.forEach((pendingPayment: any) => {
            if (
                !this._isEcheck(pendingPayment) &&
                !this._isCreditCard(pendingPayment)
            ) {
                pendingPayment.status = PendingPaymentStatus.NO_ACTION;
            } else if (this._isEcheckRefund(pendingPayment)) {
                pendingPayment.status = PendingPaymentStatus.REFUND;
            } else if (
                this._isEcheck(pendingPayment) &&
                pendingPayment.requiresTwoApprovers
            ) {
                if (!pendingPayment.echeck.firstApprover) {
                    pendingPayment.status = PendingPaymentStatus.APPROVE_1;
                } else if (
                    pendingPayment.echeck.firstApprover ===
                        this._getCurrentUsername() &&
                    !pendingPayment.echeck.secondApprover
                ) {
                    pendingPayment.status = PendingPaymentStatus.WAITING;
                } else if (
                    pendingPayment.echeck.firstApprover !==
                        this._getCurrentUsername() &&
                    !pendingPayment.echeck.secondApprover
                ) {
                    pendingPayment.status = PendingPaymentStatus.APPROVE_2;
                } else {
                    pendingPayment.status =
                        PendingPaymentStatus.PAYMENT_PROCESSING; // don't allow edit payments for orgs with 2 approvers
                }
            } else if (pendingPayment.approved) {
                pendingPayment.status =
                    PendingPaymentStatus.APPROVED_NOT_PRINTED;
            } else if (pendingPayment.echeck?.printed) {
                pendingPayment.status = PendingPaymentStatus.PAYMENT_PROCESSING;
            } else if (
                this._isCreditCard(pendingPayment) &&
                !pendingPayment.errorMessage
            ) {
                pendingPayment.status = PendingPaymentStatus.READY;
            } else {
                pendingPayment.status = PendingPaymentStatus.APPROVE_2;
            }
        });
    }

    private _getCurrentUsername(): string {
        return this._sessionService.getUsername();
    }

    private _submitValidEcheckPayments(payments: any[], lock?: any) {
        //compare against check numbers in db table
        const clonedPayments = clone(payments);
        clonedPayments.forEach((pendingPayment) => {
            delete pendingPayment.isChild;
            delete pendingPayment.label;
            delete pendingPayment.packageID;
            delete pendingPayment.paymentStatus;
            delete pendingPayment.status;
            delete pendingPayment.editMode;
            delete pendingPayment.refundAccounts;
            delete pendingPayment.paymentMethod;
        });
        this._submitterPaymentService
            .findDuplicateCheckNumbers(clonedPayments)
            .subscribe((results) => {
                if (results && Object.keys(results).length > 0) {
                    this._showDuplicateCheckDialog(results, payments);
                    //unlock access here
                    if (lock) lock.release();
                } else {
                    // if there are CC payments to include add them in now
                    //pass the lock down for unlocking when async processes are complete
                    this._submitPayments(payments, lock);
                }
            });
    }

    private _submitPayments(payments: any[], lock?: any) {
        // echeck payments have been validated and are ready to send. CC payments are ready to send.
        let paymentsToSend: any = {};

        payments.forEach((payment: any) => {
            let pendingPayment: any;
            // let's check if paymentsToSend has the org ID as a key.
            // if not, add it like we would in line 1416, else continue
            if (!paymentsToSend.hasOwnProperty(payment.organizationID)) {
                paymentsToSend[payment.organizationID] = [];
            }

            // Logic if an echeck payment - payment.pendingPaymentMethodType === 'ECHECK'
            if (payment.pendingPaymentMethodType === "ECHECK") {
                if (this._canSubmit(payment)) {
                    switch (payment.status) {
                        case PendingPaymentStatus.REFUND:
                            delete payment.refundAccounts;
                            paymentsToSend[payment.organizationID].push(
                                payment
                            );
                            break;
                        case PendingPaymentStatus.APPROVE_1:
                            this.transactionsForm
                                .get(
                                    `${payment.echeck.echeckID}.echeck.firstApprover`
                                )
                                .patchValue(this._getCurrentUsername());
                            this.transactionsForm
                                .get(`${payment.echeck.echeckID}.status`)
                                .patchValue(PendingPaymentStatus.WAITING);
                            pendingPayment = this.transactionsForm.get(
                                `${payment.echeck.echeckID}`
                            ).value;
                            paymentsToSend[payment.organizationID].push(
                                pendingPayment
                            );
                            break;
                        case PendingPaymentStatus.APPROVE_2:
                            this.transactionsForm
                                .get(
                                    `${payment.echeck.echeckID}.echeck.secondApprover`
                                )
                                .patchValue(this._getCurrentUsername());
                            this.transactionsForm
                                .get(`${payment.echeck.echeckID}.status`)
                                .patchValue(PendingPaymentStatus.PROCESSING);
                            pendingPayment = this.transactionsForm.get(
                                `${payment.echeck.echeckID}`
                            ).value;
                            paymentsToSend[payment.organizationID].push(
                                pendingPayment
                            );
                            break;
                        default:
                            break;
                    }
                }
            } else if (payment.pendingPaymentMethodType === "CREDIT_CARD") {
                // if (payment.paymentAccountID === payment.badPaymentAccountID) {
                //     this._growlService.error(
                //         `Payment retry failed for package: ${payment.items[0].label}. Payment account was not changed.`,
                //         null,
                //         { disableTimeOut: true }
                //     );
                //     setTimeout(() => this._focusAway()); // set focus away
                //     return;
                // }
                paymentsToSend[payment.organizationID].push(payment);
            }
        });

        // Verify that there are payments to save
        const keys: string[] = Object.keys(paymentsToSend);
        let sendPayments: boolean = false;
        keys.forEach((key: string) => {
            if (paymentsToSend[key].length > 0) {
                sendPayments = true;
            }
        });

        if (sendPayments) {
            switch (this.selectedPaymentType) {
                case "CREDIT_CARD":
                    if (this._multiplePaymentsSelected) {
                        this._savePaymentAccounts = true;
                    } else {
                        this._savePaymentAccount = true;
                    }
                    this._gridApi?.showLoadingOverlay();
                    const observables: Observable<any>[] = [];
                    keys.forEach((orgID: string) => {
                        paymentsToSend[orgID].forEach((payment: any) => {
                            if (payment.packageID) {
                                observables.push(
                                    this._pendingPaymentsNewService
                                        .setPackagePaymentAccounts(
                                            payment.packageID,
                                            payment.paymentAccountID,
                                            payment.editableCategories
                                        )
                                        .pipe(
                                            catchError((err: any) =>
                                                of({
                                                    error: err,
                                                    isError: true
                                                })
                                            )
                                        )
                                );
                            }
                        });
                    });
                    forkJoin(...observables)
                        .pipe(
                            defaultIfEmpty([]),
                            switchMap((res: any) => {
                                const hasErrors: boolean = res.some(
                                    (result: any) => result.isError
                                );
                                if (!hasErrors) {
                                    if (res.length > 0) {
                                        this._growlService.success(
                                            "Payment accounts saved"
                                        );
                                    }
                                    if (this._multiplePaymentsSelected) {
                                        this._savePaymentAccounts = false;
                                        this._retryPayments = true;
                                    } else {
                                        this._savePaymentAccount = false;
                                        this._retryPayment = true;
                                    }

                                    return this._submitterPaymentService.submitPendingPayments(
                                        keys,
                                        paymentsToSend,
                                        true
                                    );
                                } else {
                                    this._savePaymentAccount = false;
                                    const errorObj = {
                                        error: {
                                            errorMessage:
                                                "Payment accounts not saved."
                                        }
                                    };
                                    return throwError(errorObj);
                                }
                            })
                        )
                        .subscribe(
                            () => {
                                if (this._multiplePaymentsSelected) {
                                    this._retryPayments = false;
                                } else {
                                    this._retryPayment = false;
                                }

                                if (this._overlayPaymentType === "eCheck") {
                                    this._overlayPaymentType = "credit card";
                                }
                                this._refreshPendingPayments(true);
                                this._refreshPendingPaymentCount();
                                this._refreshBanner();
                                this._growlService.success(
                                    "Payment retries successful"
                                );
                                setTimeout(() => this._focusAway()); // set focus away
                                if (lock) lock.release();
                            },
                            (err: any) => {
                                if (this._multiplePaymentsSelected) {
                                    this._retryPayments = false;
                                } else {
                                    this._retryPayment = false;
                                }
                                this._refreshPendingPayments(true);
                                if (
                                    err.error.errorMessage ===
                                    "Payment is not currently editable."
                                ) {
                                    this._growlService.error(
                                        `Payment retry failed: ${err.error.errorMessage} Please verify that the payment still needs to be retried.`,
                                        null,
                                        { disableTimeOut: true }
                                    );
                                } else {
                                    const errorMessage =
                                        this._formatBatchCCErrorMessage(
                                            err.error.errorMessage
                                        );
                                    this._growlService.error(
                                        `${errorMessage}`,
                                        null,
                                        {
                                            disableTimeOut: true,
                                            enableHtml: true
                                        }
                                    );
                                }
                                setTimeout(() => this._focusAway()); // set focus away
                                if (lock) lock.release();
                            }
                        );
                    break;
                case "ECHECK":
                    this._submitterPaymentService
                        .submitPendingPayments(keys, paymentsToSend, false)
                        .subscribe(
                            () => {
                                let containsRefunds: boolean = false;
                                keys.forEach((orgID: string) => {
                                    if (paymentsToSend[orgID].length > 0) {
                                        paymentsToSend[orgID].forEach(
                                            (payment: any) => {
                                                if (payment.isRefund) {
                                                    containsRefunds = true;
                                                }
                                            }
                                        );
                                    }
                                });
                                this._refreshPendingPayments(true);
                                this._refreshPendingPaymentCount();
                                this._refreshBanner();
                                if (containsRefunds) {
                                    const refundMessage: string = `Thank you for submitting your refund request. The request will be reviewed by our accounting team and processed accordingly. If you have any questions please email <a href="mailto:accountants@simplifile.com">accountants@simplifile.com</a> referencing "eCheck refund" and the package name in the subject line.`;
                                    this._growlService.success(refundMessage);
                                }
                                if (lock) lock.release();
                            },
                            (error) => {
                                // keys.forEach((orgID: string) => {
                                //     if (paymentsToSend[orgID].length > 0) {
                                //         this._refreshPaymentStatus(
                                //             paymentsToSend[orgID]
                                //         );
                                //     }
                                // });  do we need this? was causing strange behavior in the UI after getting an error approving an echeck
                                this._growlService.error(
                                    error.error.errorMessage
                                );
                                if (lock) lock.release();
                            }
                        );
                    break;
                default:
                    break;
            }
        }
    }

    private _formatBatchCCErrorMessage(errorMessage: any): any {
        return errorMessage
            .replaceAll("\n", "<br/>")
            .replaceAll("Your", "<b>Your")
            .replaceAll("these packages:", "these packages:</b>")
            .replaceAll("this package:", "this package:</b>")
            .replaceAll("Payments failed", "<b>Payments failed")
            .replaceAll("Payment failed", "<b>Payment failed")
            .replaceAll("following reasons:", "following reasons:</b>")
            .replaceAll("following reason:", "following reason:</b>");
    }

    private _formatPaymentAccountSwitchedMessage(
        pendingPaymentLabel: string,
        switchedAccountType: string,
        switchedAccountLabel: string,
        isRecording: boolean,
        isNewCard?: boolean,
        hideLink?: boolean,
        isImmediateRetry?: boolean
    ): string {
        return hideLink
            ? `Payment account for ${
                  // This is for adding a new card to a CC payment and all license payments too?
                  isRecording ? "package" : ""
              } ${pendingPaymentLabel} was successfully switched to ${switchedAccountLabel}. ${
                  isNewCard && isRecording && !isImmediateRetry
                      ? "This payment will be processed at end of day."
                      : "Payment was retried successfully."
              }`
            : `Payment account for ${
                  isRecording ? "package" : ""
              } ${pendingPaymentLabel} was successfully switched to ${switchedAccountLabel}. ${
                  isNewCard && isRecording && !isImmediateRetry
                      ? "This payment will be processed at end of day."
                      : ""
              } <a href="submitter/payments/pending-payments?selectedPaymentType=${switchedAccountType}&growlRedirect=true">View Payment</a> here.`;
    }

    private _refreshBanner() {
        this._userorgService.clearCachedOrgsAndUsers();
        this._bannerService.recheckCategory(
            BannerNotificationCategory.PENDING_PAYMENTS
        );
        this._bannerService.recheckCategory(
            BannerNotificationCategory.SUSPENDED_SERVICE
        );
    }

    private _refreshPendingPaymentCount() {
        this._paymentNavTickersService.refreshPendingPaymentCount();
    }

    private _canSubmit(pendingPayment: any): boolean {
        if (!pendingPayment.status) {
            return false;
        }
        if (this._isEcheckPayment(pendingPayment)) {
            const echeck = pendingPayment.echeck;
            return (
                echeck &&
                echeck.checkNumber &&
                this._isValidCheckNumber(echeck.checkNumber)
            );
        } else if (this._isEcheckRefund(pendingPayment)) {
            return !!pendingPayment.targetAccountID;
        }
        return false;
    }

    private _showDuplicateCheckDialog(duplicateInfo: any, payments: any[]) {
        const modalRef = this._modalService.open(
            DuplicateCheckNumberComponent,
            {
                backdrop: "static",
                size: "lg"
            }
        );

        const modalInstance = modalRef.componentInstance;

        modalInstance.duplicateInfo = duplicateInfo;
        // modalInstance.duplicateCheckNumbers = duplicateChecks.sort(
        //     (a: any, b: any) => a - b
        // );
        payments.sort((a: any, b: any) =>
            a["label"].localeCompare(b["label"], undefined, {
                numeric: true,
                sensitivity: "base"
            })
        );

        modalRef.result.then(
            (fix: boolean) => {
                if (fix) {
                    // const focusedPaymentID: string = payments.find(
                    //     (payment) =>
                    //         payment.echeck.checkNumber === duplicateChecks[0]
                    // ).echeck.echeckID;
                    // let inputElement =
                    //     document.getElementById(focusedPaymentID);
                    // setTimeout(() => inputElement.focus());
                } else {
                    this._submitPayments(payments);
                }
            },
            () => {
                setTimeout(() => this._focusAway()); // set focus away
            }
        );
    }

    private _setUpEcheckObject(pendingEcheck: any) {
        if (!pendingEcheck.checkNumber) {
            pendingEcheck.checkNumber = "";
        }
        pendingEcheck.checkNumber = [
            pendingEcheck.checkNumber,
            {
                validators: [Validators.required, Validators.maxLength(10)]
            }
        ];
        pendingEcheck.memo = [pendingEcheck.memo, [Validators.required]];
        pendingEcheck.transactionItemIDs = [pendingEcheck.transactionItemIDs];
        if (!pendingEcheck.firstApprover) {
            pendingEcheck.firstApprover = null;
        }
        if (!pendingEcheck.secondApprover) {
            pendingEcheck.secondApprover = null;
        }
        //PS-19178 this ensures that the echeck data used on the transactions form has a "modifiedDate" property
        if (!pendingEcheck.modifiedDate) {
            pendingEcheck.modifiedDate = null;
        }
        return pendingEcheck;
    }

    private _addCheckInfo(pendingPayment: any, pArray: any[]) {
        if (
            pendingPayment &&
            pendingPayment.paymentAccountID &&
            pendingPayment.items[0] &&
            this._isEcheckPayment(pendingPayment)
        ) {
            if (pendingPayment.isApproved) {
                pendingPayment.editApprovedEcheck = true;
            }
            pArray.push(pendingPayment.echeck);
        }
        return pArray;
    }

    private _isEcheckRefund(pendingPayment: any) {
        return this._isEcheck(pendingPayment) && pendingPayment.isRefund;
    }

    private _isEcheckPayment(pendingPayment: any) {
        return this._isEcheck(pendingPayment) && !pendingPayment.isRefund;
    }

    private _isEcheck(pendingPayment: any) {
        return (
            pendingPayment &&
            pendingPayment.pendingPaymentMethodType === "ECHECK"
        );
    }

    private _isCreditCard(pendingPayment: any) {
        return (
            pendingPayment &&
            pendingPayment.pendingPaymentMethodType === "CREDIT_CARD"
        );
    }

    private _hasOrgPermission(org: SessionOrganization): boolean {
        //add flag to return true if in dialog mode
        if (this.dialogMode) {
            return true;
        }
        let validOrg: boolean = false;
        validOrg =
            validOrg || this._sessionService.hasProduct(org.id, "submitter");
        validOrg = validOrg || this._sessionService.hasProduct(org.id, "capc");
        validOrg =
            validOrg || this._sessionService.hasProduct(org.id, "esign_event");
        validOrg =
            validOrg || this._sessionService.hasProduct(org.id, "notary");
        validOrg =
            validOrg || this._sessionService.hasProduct(org.id, "trustee");
        return (
            this._sessionService.hasPermission(
                "organization_payment_approval",
                org.id
            ) && validOrg
        );
    }

    private _getOrgSelectionObj(
        org: SessionOrganization
    ): PendingPaymentsOrgFilterSelectionObject {
        return {
            value: org.id,
            option: org.id,
            title: org.name,
            label: org.name
        };
    }

    private _getHasPermissionToManageAccounts(orgIDs?: string[]): boolean {
        if (this.dialogMode) {
            return false;
        }

        if (!orgIDs || orgIDs.length > 1) {
            return this._sessionService.hasPermissionInAnyOrg(
                "organization_accounting"
            );
        }
        if (orgIDs.length === 1) {
            return this._sessionService.hasPermission(
                "organization_accounting",
                orgIDs[0]
            );
        }
        return false;
    }

    private _getPendingPaymentsDisplaySettingsObject(
        orgs: PendingPaymentsOrgFilterSelectionObject[]
    ): PendingPaymentsDisplaySettingsObject {
        // We will always be getting
        let selectedPaymentType: string = null;

        if (this.showEcheckSelector) {
            this._orgPaymentTypeOptionsArr.push("MYECHECK");
        }
        if (this.showCreditCardSelector) {
            this._orgPaymentTypeOptionsArr.push("CREDIT_CARD");
        }
        if (this.showACHSelector) {
            this._orgPaymentTypeOptionsArr.push("ACH");
        }

        let defaultDisplayObject: PendingPaymentsDisplaySettingsObject = {
            selectedOrgOption: orgs.length > 1 ? "none" : orgs[0].value,
            selectedOrgIDArray: [
                orgs.map(
                    (org: PendingPaymentsOrgFilterSelectionObject) => org.value
                )
            ],
            selectedPaymentType: selectedPaymentType,
            hideApprovedPayments: this.userSettingsService.getUserSetting(
                "hide_approved_echeck_payments"
            ),
            canManageSomeAccounts: this._getHasPermissionToManageAccounts(),
            canSubmit: false,
            isSuperUser: this._sessionService.isSuperUser()
        };

        if (defaultDisplayObject.isSuperUser) {
            defaultDisplayObject.hasOverridePermission =
                this._sessionService.hasPermissionInAnyOrg(
                    "admin_manage_collections"
                );
        }

        return defaultDisplayObject;
    }

    private _isValidCheckNumber(checkNumber: string) {
        return PendingPaymentsComponent._CHECK_NUMBER_REGEX.test(checkNumber);
    }

    private _splitCheckNumber(checkNumber: string) {
        if (!checkNumber || checkNumber.length === 1) {
            return ["", parseInt(checkNumber)];
        }
        const first: string = checkNumber.charAt(0);

        if (first.match(/[a-zA-Z]/i)) {
            return [first, parseInt(checkNumber.substring(1))];
        }

        return ["", parseInt(checkNumber)];
    }

    private _refreshPendingPayments(
        clearCache?: boolean,
        orgChanged?: boolean
    ) {
        this._gridApi?.showLoadingOverlay();
        this.pendingPaymentCountsLoaded = false;
        const filterOrgs: string[] =
            this.pendingPaymentsDisplayForm.get("selectedOrgOption").value ===
            "none"
                ? this._allOrgsIDArray
                : [
                      this.pendingPaymentsDisplayForm.get("selectedOrgOption")
                          .value
                  ];
        const paymentTypeFilterOption: string = orgChanged
            ? this._setPaymentTypeFilterOption()
            : this.pendingPaymentsDisplayForm.get("selectedPaymentType").value;
        this._hasPaymentTypeFilterOption = !!paymentTypeFilterOption;
        const hideApprovedPayments: boolean =
            this.pendingPaymentsDisplayForm.get("hideApprovedPayments").value;
        this._pendingPaymentsDataService.getUIPendingPayments(
            this._allOrgsIDArray,
            filterOrgs,
            this._orgPaymentTypeOptionsArr,
            paymentTypeFilterOption,
            hideApprovedPayments,
            clearCache,
            this._allPendingPayments
        );
        // this is to make sure we get new pending payments when payments are approved
        if (!this._allPendingPaymentsUsed) {
            this._allPendingPayments = null;
            this._allPendingPaymentsUsed = true;
        }
    }

    private _setPaymentTypeFilterOption(): string {
        if (
            !this.showEcheckSelector &&
            !this.showCreditCardSelector &&
            !this.showACHSelector
        ) {
            return "NONE";
        }
    }

    private _setupGridOverlay() {
        const noRowsOverlayParams: any = {
            paymentTypes: () => this._overlayPaymentType,
            org: () => this._overlayOrg
        };
        const loadingOverlayParams: any = {
            loadingOverlayParamsFunc: () => {
                if (this._retryPayment) {
                    return "Retrying payment...";
                } else if (this._retryPayments) {
                    return "Retrying payments...";
                } else if (this._savePaymentAccount) {
                    return "Saving payment account...";
                } else if (this._savePaymentAccounts) {
                    return "Saving payment accounts...";
                } else {
                    return `Loading pending ${this._overlayPaymentType} payments for ${this._overlayOrg}...`;
                }
            }
        };
        this.frameworkComponents = {
            pendingPaymentsLoadingOverlay:
                PendingPaymentsLoadingOverlayComponent,
            pendingPaymentsNoRowsOverlay: PendingPaymentsNoRowsOverlayComponent
        };
        this.loadingOverlayComponent = "pendingPaymentsLoadingOverlay";
        this.loadingOverlayComponentParams = loadingOverlayParams;
        this.noRowsOverlayComponent = "pendingPaymentsNoRowsOverlay";
        this.noRowsOverlayComponentParams = noRowsOverlayParams;
    }

    private _catchFormChanges() {
        this.pendingPaymentsDisplayForm
            .get("selectedOrgOption")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                this.canClearSelectedOrg = true;
                if (val === this._initialSelectedOrg && this._firstClick) {
                    this._firstClick = false;
                } else {
                    this.pendingPaymentCountsLoaded = false;
                    this._firstClick = false;
                    if (!val) {
                        this.pendingPaymentsDisplayForm
                            .get("selectedOrgOption")
                            .patchValue("none");
                        this.canClearSelectedOrg = false;
                        setTimeout(() => {
                            this._columnApi.setColumnVisible(
                                "organizationID",
                                true
                            );
                        });
                    } else if (val === "none") {
                        this.canClearSelectedOrg = false;
                        setTimeout(() => {
                            this._columnApi.setColumnVisible(
                                "organizationID",
                                true
                            );
                        });
                    }
                    this._setAccountSelectorsVisible(
                        this.pendingPaymentsDisplayForm.get("selectedOrgOption")
                            .value
                    );
                    this._orgChanged = true;
                    this.hasDelinquentEcheckPayments = false;
                    this.hasDelinquentCreditCardPayments = false;
                    this._overlayOrg =
                        val && val !== "none"
                            ? this._sessionService.getOrganizationName(val)
                            : `All Organizations`;
                    this._refreshPendingPayments(false, true);
                }
            });

        this.pendingPaymentsDisplayForm
            .get("selectedPaymentType")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                this.pendingPaymentCountsLoaded = false;
                if (val === "ECHECK") {
                    this.selectedPaymentType = val;
                    this._overlayPaymentType = val
                        .toLowerCase()
                        .replace("echeck", "eCheck");
                    setTimeout(() => {
                        this._columnApi?.setColumnsVisible(
                            this._echeckOnlyPendingPaymentColumns,
                            true
                        );
                        this._columnApi?.setColumnsVisible(
                            this._creditCardOnlyPendingPaymentColumns,
                            false
                        );
                        this._columnApi?.applyColumnState({
                            state: this._defaultEcheckSort
                        });
                    });
                } else if (val === "CREDIT_CARD") {
                    this.selectedPaymentType = val;
                    this._overlayPaymentType = val
                        .toLowerCase()
                        .replace("credit_card", "credit card");
                    setTimeout(() => {
                        this._columnApi?.setColumnsVisible(
                            this._creditCardOnlyPendingPaymentColumns,
                            true
                        );
                        this._columnApi?.setColumnsVisible(
                            this._echeckOnlyPendingPaymentColumns,
                            false
                        );

                        this._columnApi?.applyColumnState({
                            state: this._defaultCreditCardSort
                        });
                    });
                } else {
                    this.selectedPaymentType = val;
                    this._overlayPaymentType = val;
                    setTimeout(() => {
                        this._columnApi?.setColumnsVisible(
                            this._echeckOnlyPendingPaymentColumns.concat(
                                this._creditCardOnlyPendingPaymentColumns
                            ),
                            false
                        );
                    });
                }
                this._refreshPendingPayments(false);
            });

        this.pendingPaymentsDisplayForm
            .get("hideApprovedPayments")
            .valueChanges.pipe(takeUntil(this._onDestroy))
            .subscribe((val) => {
                this.userSettingsService.setUserSetting(
                    "hide_approved_echeck_payments",
                    val
                );
                this.hideApproved();
            });
    }

    private _setupPendingPaymentLabels(payments: any) {
        // reset these any time we need to do this setup
        this.hasAnyEcheckPayments = false;
        this.hasAnyCreditCardPayments = false;
        this.hasDelinquentEcheckPayments = false;
        this.hasDelinquentCreditCardPayments = false;

        return payments.map((pendingPayment: any) => {
            this._refreshPaymentStatus([pendingPayment]);
            if (
                pendingPayment.pendingPaymentMethodType === "ECHECK" ||
                (pendingPayment.pendingPaymentMethodType === "CREDIT_CARD" &&
                    pendingPayment.errorMessage)
            ) {
                if (pendingPayment.isDelinquent) {
                    this.delinquentTotal += parseFloat(
                        pendingPayment.feeTotals.total
                    );
                    this.delinquentCount++;
                    if (
                        pendingPayment.pendingPaymentMethodType === "ECHECK" &&
                        !this.hasDelinquentEcheckPayments
                    ) {
                        this.hasDelinquentEcheckPayments = true;
                    } else if (
                        pendingPayment.pendingPaymentMethodType ===
                            "CREDIT_CARD" &&
                        !this.hasDelinquentCreditCardPayments
                    ) {
                        this.hasDelinquentCreditCardPayments = true;
                    }
                } else {
                    if (
                        pendingPayment.pendingPaymentMethodType === "ECHECK" &&
                        !this.hasAnyEcheckPayments
                    ) {
                        this.hasAnyEcheckPayments = true;
                    } else if (
                        pendingPayment.pendingPaymentMethodType ===
                            "CREDIT_CARD" &&
                        !this.hasAnyCreditCardPayments
                    ) {
                        this.hasAnyCreditCardPayments = true;
                    }
                }
            }
            if (this.dialogMode) {
                if (pendingPayment.items.length > 1) {
                    // to add for child ACH items
                    pendingPayment.items.forEach((item: any) => {
                        item.inDialogMode = this.dialogMode;
                    });
                }
                pendingPayment.inDialogMode = this.dialogMode; // This is only added in dialog mode to echeck/CC object so we can disable row selection AND disable account selector for open balances
            }
            return pendingPayment;
        });
    }

    private _setSelectedPaymentType(paymentType: string) {
        if (
            (!this.showEcheckSelector &&
                !this.showCreditCardSelector &&
                !this.showACHSelector) ||
            (!paymentType && !this.selectedPaymentType)
        ) {
            this.pendingPaymentsDisplayForm
                .get("selectedPaymentType")
                .patchValue("", { emitEvent: false });
            this._overlayPaymentType = "";
        } else if (
            paymentType === "ACH" ||
            (!paymentType && this.selectedPaymentType === "ACH")
        ) {
            this.pendingPaymentsDisplayForm
                .get("selectedPaymentType")
                .patchValue("ACH", { emitEvent: false });
            this._overlayPaymentType = "ACH";
        } else if (
            paymentType === "CREDIT_CARD" ||
            (!paymentType && this.selectedPaymentType === "CREDIT_CARD")
        ) {
            this.pendingPaymentsDisplayForm
                .get("selectedPaymentType")
                .patchValue("CREDIT_CARD", { emitEvent: false });
            this._overlayPaymentType = "credit card";
        } else if (
            paymentType === "ECHECK" ||
            (!paymentType && this.selectedPaymentType === "ECHECK")
        ) {
            this.pendingPaymentsDisplayForm
                .get("selectedPaymentType")
                .patchValue("ECHECK", { emitEvent: false }); // most likely default option
            this._overlayPaymentType = "eCheck";
        }
        this.selectedPaymentType = this.pendingPaymentsDisplayForm.get(
            "selectedPaymentType"
        ).value;
        this._orgChanged = false;
        if (!this.dialogMode) {
            this._switchPaymentTypeRouting(this.selectedPaymentType);
        }
    }

    private _switchPaymentTypeRouting(selectedPaymentType: string) {
        this._router.navigate([], {
            relativeTo: this._route,
            queryParams: {
                selectedPaymentType: selectedPaymentType
            },
            state: {
                prevPage: this._router.url
            },
            replaceUrl: true
        });
    }

    private _setupPendingPaymentForms(payments: any) {
        payments.forEach((payment: any) => {
            const orgPaymentAccountsSelectorOptions = clone(
                payment.orgPaymentAccounts
            );
            payment.orgPaymentAccounts = [payment.orgPaymentAccounts];
            payment.editableCategories = [payment.editableCategories];
            if (
                payment.pendingPaymentMethodType === "ECHECK" &&
                payment.echeck
            ) {
                let echeckFormObject = clone(payment.echeck); // set up echeck object to be used in form builder
                delete payment.echeck; // so we can add it as a form group later
                this.transactionsForm.addControl(
                    // set up payment as a form control of transactions form
                    echeckFormObject.echeckID,
                    this._fb.group(payment)
                );
                payment.items = payment.items.flat(); // so hide approved works and must be done after control added to form
                payment.echeck = echeckFormObject;
                echeckFormObject = this._setUpEcheckObject(echeckFormObject);
                // TODO: Extract to private method to set up form and value change subscriptions?
                (
                    this.transactionsForm.get(
                        // add echeck info as own form/form control for the payment form
                        `${echeckFormObject.echeckID}`
                    ) as FormGroup
                ).addControl("echeck", this._fb.group(echeckFormObject));
                payment.orgPaymentAccounts = orgPaymentAccountsSelectorOptions;
                this._setupPendingEcheckPaymentFormValueChanges(
                    payment,
                    echeckFormObject
                );
            } else if (payment.pendingPaymentMethodType === "CREDIT_CARD") {
                if (!payment.orgCCAccountsSelectorOptions) {
                    // Needed so we don't have weird data structure for sf-select and accounts still show
                    payment.orgCCAccountsSelectorOptions =
                        payment.orgCCAccounts;
                    payment.orgCCAccounts = [payment.orgCCAccounts];
                }
                this.transactionsForm.addControl(
                    // set up payment as a form control of transactions form
                    payment.failedTransactionItemID,
                    this._fb.group(payment)
                );
                if (this._addedNewCreditCard) {
                    this.transactionsForm
                        .get(
                            `${payment.failedTransactionItemID}.orgPaymentAccounts`
                        )
                        .patchValue(payment.orgPaymentAccounts[0]);
                }
                payment.orgPaymentAccounts = orgPaymentAccountsSelectorOptions;
                this._setupPendingCreditCardPaymentFormValueChanges(
                    payment.failedTransactionItemID
                );
                payment.items = payment.items.flat(); // so hide approved works and must be done after control added to form
            } else if (payment.pendingPaymentMethodType === "ACH") {
                if (payment.items.length === 1) {
                    this.transactionsForm.addControl(
                        // set up payment as a form control of transactions form
                        payment.achReferenceID,
                        this._fb.group(payment)
                    );
                    this._setupPendingACHPaymentFormValueChanges(
                        payment.achReferenceID
                    );
                    payment.orgPaymentAccounts =
                        orgPaymentAccountsSelectorOptions;
                    payment.editableCategories = payment.editableCategories[0];
                } else {
                    payment.items.forEach((item: any) => {
                        item.orgPaymentAccounts = [item.orgPaymentAccounts];
                        if (!Array.isArray(item.editableCategories[0])) {
                            item.editableCategories = [item.editableCategories];
                        }
                        this.transactionsForm.addControl(
                            // set up payment as a form control of transactions form
                            item.achReferenceID,
                            this._fb.group(item)
                        );
                        this._setupPendingACHPaymentFormValueChanges(
                            item.achReferenceID
                        );
                        item.orgPaymentAccounts = item.orgPaymentAccounts[0];
                        item.editableCategories =
                            item.editableCategories?.length > 0
                                ? item.editableCategories[0]
                                : null;
                    });
                }
            }
            payment.paymentStatus = this.calculatePillText(payment); // update the status pill after the forms are set up
        });
    }

    private _setupPendingACHPaymentFormValueChanges(achReferenceID: string) {
        const oldPaymentAccountID: string = this.transactionsForm.get(
            `${achReferenceID}.oldPaymentAccountID`
        ).value; // Does this update if the payment account switches to another ACH account?
        this.transactionsForm
            .get(`${achReferenceID}.paymentAccountID`)
            .valueChanges.pipe(
                takeUntil(this.stop$),
                startWith(oldPaymentAccountID),
                distinctUntilChanged(),
                skip(1),
                switchMap((paymentAccountID: string) => {
                    const pendingPayment = this.transactionsForm.get(
                        `${achReferenceID}`
                    ).value;
                    const switchedPaymentAccount: any =
                        pendingPayment.orgPaymentAccounts.find(
                            (account: any) => account.id === paymentAccountID
                        );
                    const isAccountTypeSwitched: boolean =
                        switchedPaymentAccount.type !== "ACH";
                    if (
                        switchedPaymentAccount.type === "CREDIT_CARD" &&
                        switchedPaymentAccount.label.indexOf("(Debit") === -1 &&
                        pendingPayment.pendingPaymentType === "RECORDING"
                    ) {
                        return this._confirmSurcharge(
                            pendingPayment,
                            paymentAccountID,
                            isAccountTypeSwitched,
                            switchedPaymentAccount,
                            pendingPayment.organizationID,
                            this._getTotalWithoutSurcharge(
                                pendingPayment.feeTotals
                            )
                        );
                    } else {
                        return of({
                            pendingPayment: pendingPayment,
                            paymentAccountID: paymentAccountID,
                            isAccountTypeSwitched: isAccountTypeSwitched,
                            switchedPaymentAccount: switchedPaymentAccount,
                            surchargeConfirmed: true // so payment account will save and switch, not that a debit card needs to confirm surcharge....but....
                        });
                    }
                }),
                switchMap((confirmSurchargeRes: ConfirmSurcharge) => {
                    // short circuit the rest of the process if the surcharges are not confirmed
                    if (!confirmSurchargeRes.surchargeConfirmed) {
                        this._refreshPendingPayments(true, false);
                        return of([]);
                    }
                    this._savePaymentAccount = true;
                    this._gridApi?.showLoadingOverlay();
                    /* FIXME: convert to a common method to use in other value change (CC and Echeck)*/
                    if (
                        confirmSurchargeRes.pendingPayment
                            .pendingPaymentType === "RECORDING"
                    ) {
                        return this._switchRecordingPendingPayment(
                            confirmSurchargeRes.pendingPayment,
                            confirmSurchargeRes.paymentAccountID,
                            confirmSurchargeRes.isAccountTypeSwitched,
                            confirmSurchargeRes.switchedPaymentAccount
                        );
                    } else if (
                        confirmSurchargeRes.pendingPayment
                            .pendingPaymentType === "LICENSE" &&
                        confirmSurchargeRes.switchedPaymentAccount.type !==
                            "CREDIT_CARD"
                    ) {
                        return this._switchLicensePendingPaymentToNotCreditCard(
                            confirmSurchargeRes.pendingPayment,
                            confirmSurchargeRes.paymentAccountID,
                            oldPaymentAccountID,
                            confirmSurchargeRes.isAccountTypeSwitched,
                            confirmSurchargeRes.switchedPaymentAccount
                        );
                    } else if (
                        confirmSurchargeRes.pendingPayment
                            .pendingPaymentType === "LICENSE" &&
                        confirmSurchargeRes.switchedPaymentAccount.type ===
                            "CREDIT_CARD"
                    ) {
                        return this._switchLicensePendingPaymentToCreditCard(
                            confirmSurchargeRes.pendingPayment,
                            "ACH",
                            confirmSurchargeRes.switchedPaymentAccount,
                            confirmSurchargeRes.paymentAccountID
                        );
                    }
                    // Manual transactions and refunds not yet enabled
                    // else {
                    //     this._growlService.warning(
                    //         "manual transactions not enabled"
                    //     );
                    //     return of([]);
                    // }
                    return null;
                })
            )
            .subscribe();
    }

    /**
     * @param failedTransactionItemID
     */
    private _setupPendingCreditCardPaymentFormValueChanges(
        failedTransactionItemID: string
    ) {
        const badPaymentAccountID: string = this.transactionsForm.get(
            `${failedTransactionItemID}.paymentAccountID`
        ).value;
        this.transactionsForm
            .get(`${failedTransactionItemID}.paymentAccountID`)
            .valueChanges.pipe(
                takeUntil(this.stop$),
                startWith(badPaymentAccountID),
                distinctUntilChanged(),
                skip(1),
                switchMap((paymentAccountID: string) => {
                    const pendingPayment = this.transactionsForm.get(
                        `${failedTransactionItemID}`
                    ).value;
                    const switchedPaymentAccount: any =
                        pendingPayment.orgPaymentAccounts.find(
                            (account: any) => account.id === paymentAccountID
                        );
                    const isAccountTypeSwitched: boolean =
                        switchedPaymentAccount.type !== "CREDIT_CARD";
                    if (
                        (isAccountTypeSwitched ||
                            !pendingPayment.errorMessage) &&
                        pendingPayment.pendingPaymentType === "RECORDING"
                    ) {
                        this._savePaymentAccount = true;
                        this._gridApi?.showLoadingOverlay();
                        return this._switchRecordingPendingPayment(
                            pendingPayment,
                            paymentAccountID,
                            isAccountTypeSwitched,
                            switchedPaymentAccount
                        );
                    } else if (
                        isAccountTypeSwitched &&
                        pendingPayment.pendingPaymentType === "LICENSE"
                    ) {
                        return this._switchLicensePendingPaymentToNotCreditCard(
                            pendingPayment,
                            paymentAccountID,
                            badPaymentAccountID,
                            isAccountTypeSwitched,
                            switchedPaymentAccount
                        );
                    } else if (
                        !isAccountTypeSwitched ||
                        pendingPayment.errorMessage
                    ) {
                        // switched pending cc to another cc or bad cc to another cc
                        //NOTE: all this does is update in-memory cache (not sure if needed with other changes?)
                        // this._pendingPaymentsDataService.updatePaymentAccount(
                        //     failedTransactionItemID,
                        //     this.transactionsForm.get(
                        //         `${failedTransactionItemID}.paymentAccountID`
                        //     ).value,
                        //     this.transactionsForm.get(
                        //         `${failedTransactionItemID}.pendingPaymentMethodType`
                        //     ).value
                        // );

                        this._savePaymentAccount = true;
                        this._gridApi?.showLoadingOverlay();
                        if (pendingPayment.pendingPaymentType === "RECORDING") {
                            return this._switchRecordingPendingPayment(
                                pendingPayment,
                                paymentAccountID,
                                isAccountTypeSwitched,
                                switchedPaymentAccount
                            );
                        } else if (
                            pendingPayment.pendingPaymentType === "LICENSE"
                        ) {
                            return this._switchLicensePendingPaymentToCreditCard(
                                pendingPayment,
                                "CREDIT_CARD",
                                switchedPaymentAccount,
                                paymentAccountID
                            );
                        }

                        this._refreshPendingPaymentCount();
                        this._refreshBanner();
                        return of([]); // temporary fix here?
                    }
                    return null;
                })
            )
            .subscribe();
    }

    doEcheckUpdate(event: FocusEvent, payment: any) {
        this.selectedPaymentsAreValid();
        const editMode: boolean = this.transactionsForm.get(
            `${payment.echeck.echeckID}.editMode`
        ).value;
        if (!editMode) {
            let currPayment: any = this.transactionsForm.get(
                payment.echeck.echeckID
            ).value;
            if (
                !!currPayment.echeck.checkNumber &&
                currPayment.echeck.checkNumber !== payment.echeck.checkNumber[0]
            ) {
                this.updateEcheckNumber(currPayment);
                //updates curr value in grid so "if" clause works
                payment.echeck.checkNumber[0] = currPayment.echeck.checkNumber;
            }
        }
    }

    private _setupPendingEcheckPaymentFormValueChanges(
        payment: any,
        echeckFormObject: any
    ) {
        if (payment.pendingPaymentMethodType === "ECHECK") {
            const oldPaymentAccountID: string = this.transactionsForm.get(
                `${echeckFormObject.echeckID}.oldPaymentAccountID`
            ).value;
            //PS-19178 - don't update echeck number this way
            // this.transactionsForm // Set up valueChanges subscription for each form control
            //     .get(`${echeckFormObject.echeckID}.echeck.checkNumber`)
            //     .valueChanges.pipe(takeUntil(this.stop$), debounceTime(1000))
            //     .subscribe(() => {
            //         this.selectedPaymentsAreValid();
            //         const editMode: boolean = this.transactionsForm.get(
            //             `${echeckFormObject.echeckID}.editMode`
            //         ).value;
            //         if (!editMode) {
            //             this.updateEcheckNumber(
            //                 this.transactionsForm.get(payment.echeck.echeckID)
            //                     .value
            //             );
            //         }
            //     });
            this.transactionsForm
                .get(`${echeckFormObject.echeckID}.targetAccountID`)
                .valueChanges.pipe(takeUntil(this.stop$))
                .subscribe((targetAccountID: string) => {
                    if (
                        this.selectedPendingPaymentsIDs &&
                        this.selectedPendingPaymentsIDs.length
                    ) {
                        if (targetAccountID) {
                            this.selectedPaymentsValid =
                                !!targetAccountID.length;
                        }
                        const otherSelectedPayments: string[] =
                            this.selectedPendingPaymentsIDs.filter(
                                (paymentID: string) =>
                                    paymentID !== echeckFormObject.echeckID
                            );
                        if (otherSelectedPayments.length) {
                            otherSelectedPayments.forEach(
                                (paymentID: string) => {
                                    this.selectedPaymentsValid =
                                        !!this.transactionsForm.get(
                                            `${paymentID}.targetAccountID`
                                        ).value.length;
                                }
                            );
                        }
                    }
                });
            this.transactionsForm
                .get(`${echeckFormObject.echeckID}.paymentAccountID`)
                .valueChanges.pipe(
                    takeUntil(this.stop$),
                    startWith(oldPaymentAccountID),
                    distinctUntilChanged(),
                    skip(1),
                    switchMap((paymentAccountID: string) => {
                        const pendingPayment = this.transactionsForm.get(
                            `${echeckFormObject.echeckID}`
                        ).value;
                        const switchedPaymentAccount: any =
                            pendingPayment.orgPaymentAccounts.find(
                                (account: any) =>
                                    account.id === paymentAccountID
                            );
                        const isAccountTypeSwitched: boolean =
                            switchedPaymentAccount.type !== "ECHECK";
                        if (
                            switchedPaymentAccount.type === "CREDIT_CARD" &&
                            switchedPaymentAccount.label.indexOf("(Debit") ===
                                -1 &&
                            pendingPayment.pendingPaymentType === "RECORDING"
                        ) {
                            return this._confirmSurcharge(
                                pendingPayment,
                                paymentAccountID,
                                isAccountTypeSwitched,
                                switchedPaymentAccount,
                                pendingPayment.organizationID,
                                this._getTotalWithoutSurcharge(
                                    pendingPayment.feeTotals
                                )
                            );
                        } else {
                            return of({
                                pendingPayment: pendingPayment,
                                paymentAccountID: paymentAccountID,
                                isAccountTypeSwitched: isAccountTypeSwitched,
                                switchedPaymentAccount: switchedPaymentAccount,
                                surchargeConfirmed: true // so payment account will save and switch, not that a debit card needs to confirm surcharge....but....
                            });
                        }
                    }),
                    switchMap((surchargeResult: ConfirmSurcharge) => {
                        if (
                            surchargeResult.pendingPayment.approved &&
                            !surchargeResult.isAccountTypeSwitched
                        ) {
                            const modalRef = this._modalService.open(
                                ConfirmationModalComponent,
                                {
                                    backdrop: "static",
                                    keyboard: false
                                }
                            );
                            let surchargeGood: ConfirmSurcharge = Object.assign(
                                {},
                                surchargeResult
                            );
                            surchargeGood.surchargeConfirmed = true;
                            let surchargeBad: ConfirmSurcharge = Object.assign(
                                {},
                                surchargeResult
                            );
                            surchargeBad.surchargeConfirmed = false;

                            const modalInstance = modalRef.componentInstance;

                            modalInstance.title = "Payment Account Change";
                            modalInstance.message =
                                "Are you sure you want to change the payment account for this eCheck? " +
                                "If you change the account, you will need to re-enter the check number and memo " +
                                "information, and the eCheck will need to be approved again.";
                            modalInstance.primary = {
                                text: "Confirm Change",
                                responseValue: surchargeGood
                            };
                            modalInstance.secondary = {
                                text: "Cancel",
                                responseValue: surchargeBad
                            };
                            modalInstance.showDismiss = false;

                            return modalRef.result;
                        } else {
                            return of(surchargeResult);
                        }
                    }),
                    switchMap((surchargeResult: ConfirmSurcharge) => {
                        if (
                            !surchargeResult.pendingPayment.approved ||
                            !surchargeResult.surchargeConfirmed ||
                            surchargeResult.pendingPayment
                                .pendingPaymentType === "RETRY" ||
                            surchargeResult.pendingPayment
                                .pendingPaymentType === "RETURN_FEE"
                        )
                            return of(surchargeResult);
                        else {
                            return this._pendingPaymentsNewService
                                .deleteApprovedEcheck(echeckFormObject.echeckID)
                                .pipe(
                                    switchMap((success) => {
                                        surchargeResult.surchargeConfirmed =
                                            success;
                                        return of(surchargeResult);
                                    })
                                );
                        }
                    }),
                    switchMap((confirmSurchargeRes) => {
                        // short circuit the rest of the process if the surcharges are not confirmed
                        if (!confirmSurchargeRes.surchargeConfirmed) {
                            this._refreshPendingPayments(true, false);
                            return of([]);
                        }
                        this._savePaymentAccount = true;
                        this._gridApi?.showLoadingOverlay();
                        if (
                            confirmSurchargeRes.pendingPayment
                                .pendingPaymentType === "RECORDING"
                        ) {
                            return this._switchRecordingPendingPayment(
                                confirmSurchargeRes.pendingPayment,
                                confirmSurchargeRes.paymentAccountID,
                                confirmSurchargeRes.isAccountTypeSwitched,
                                confirmSurchargeRes.switchedPaymentAccount
                            );
                        } else if (
                            confirmSurchargeRes.pendingPayment
                                .pendingPaymentType === "LICENSE" &&
                            confirmSurchargeRes.switchedPaymentAccount.type !==
                                "CREDIT_CARD"
                        ) {
                            return this._switchLicensePendingPaymentToNotCreditCard(
                                confirmSurchargeRes.pendingPayment,
                                confirmSurchargeRes.paymentAccountID,
                                oldPaymentAccountID,
                                confirmSurchargeRes.isAccountTypeSwitched,
                                confirmSurchargeRes.switchedPaymentAccount
                            );
                        } else if (
                            confirmSurchargeRes.pendingPayment
                                .pendingPaymentType === "LICENSE" &&
                            confirmSurchargeRes.switchedPaymentAccount.type ===
                                "CREDIT_CARD"
                        ) {
                            return this._switchLicensePendingPaymentToCreditCard(
                                confirmSurchargeRes.pendingPayment,
                                "ECHECK",
                                confirmSurchargeRes.switchedPaymentAccount,
                                confirmSurchargeRes.paymentAccountID
                            );
                        } else if (
                            confirmSurchargeRes.pendingPayment
                                .pendingPaymentType === "RETRY" ||
                            confirmSurchargeRes.pendingPayment
                                .pendingPaymentType === "RETURN_FEE"
                        ) {
                            return this._switchBouncePayment(
                                confirmSurchargeRes
                            );
                        }
                        // else {
                        //     this._growlService.warning(
                        //         "manual transactions not enabled"
                        //     );
                        //     return of([]);
                        // }
                        return null;
                    })
                )
                .subscribe();
            if (this.dialogMode) {
                let checkboxValue: any = false;
                this.transactionsForm
                    .get(
                        `${echeckFormObject.echeckID}.echeck.overrideCollections`
                    )
                    .valueChanges.pipe(
                        takeUntil(this.stop$),
                        tap((val) => {
                            //save off checkbox value here
                            checkboxValue = val;
                            this.transactionsForm
                                .get(
                                    `${echeckFormObject.echeckID}.echeck.overrideCollections`
                                )
                                .patchValue(val, { emitEvent: false });
                        }),
                        switchMap(() => {
                            const payment = this.transactionsForm.get(
                                `${echeckFormObject.echeckID}`
                            ).value;
                            if (payment.inDialogMode) {
                                payment.echeck.memo = payment.echeck.memo[0];
                                payment.echeck.checkNumber =
                                    payment.echeck.checkNumber[0];
                            }
                            const echecks = [payment.echeck];
                            return this._submitterPaymentService.upsertECheckInfoNew(
                                echecks
                            );
                        }),
                        tap((val) => {
                            //val here is now the result of calling upsertECheckInfoNew() above
                            const pendingPayments = clone(
                                this.transactionsForm.value
                            );
                            this._updateOverrideCount(pendingPayments);
                            const payment = this.transactionsForm.get(
                                `${echeckFormObject.echeckID}`
                            ).value;
                            //TODO: test that this works with caching now
                            this._pendingPaymentsDataService.updateFilteredPayments(
                                checkboxValue,
                                payment.echeck.echeckID
                            );
                            payment.paymentMethod = payment.isRefund // so the method shows in the grid
                                ? "E-Check Refund"
                                : "E-Check";
                            this._gridApi
                                .getRowNode(payment.echeck.echeckID)
                                .setData(payment);
                            let row = [];
                            if (this._gridApi) {
                                row.push(
                                    this._gridApi.getRowNode(
                                        echeckFormObject.echeckID
                                    )
                                );
                                this._gridApi.redrawRows({
                                    rowNodes: row
                                }); // refresh just the row
                            }
                        })
                    )
                    .subscribe();
            }
        }
    }

    private _setupPendingPaymentTreeData(payments: any) {
        let rowItems: any[] = [];
        let rowID: number = 0;
        payments?.forEach((payment: any) => {
            rowItems.push({
                path: [`${payment.label}_${rowID}`],
                ...payment
            });
            if (
                payment.pendingPaymentMethodType === "ACH" &&
                payment.items.length > 1
            ) {
                let childID: number = 0;
                payment.items.forEach((paymentItem: any) => {
                    rowItems.push({
                        path: [
                            `${payment.label}_${rowID}`,
                            `${paymentItem.label}_${childID}`
                        ],
                        ...paymentItem
                    });
                    childID++;
                });
            }
            rowID++;
        });
        return rowItems;
    }

    private _showCorrectColumns() {
        if (
            this.pendingPaymentsDisplayForm.get("selectedOrgOption").value !==
            "none"
        ) {
            this._columnApi?.setColumnVisible("organizationID", false);
        }
        if (
            this.pendingPaymentsDisplayForm.get("selectedPaymentType").value ===
            "ACH"
        ) {
            this._columnApi?.setColumnsVisible(
                this._echeckOnlyPendingPaymentColumns.concat(
                    this._creditCardOnlyPendingPaymentColumns
                ),
                false
            );
        } else if (
            this.pendingPaymentsDisplayForm.get("selectedPaymentType").value ===
            "CREDIT_CARD"
        ) {
            this._columnApi?.setColumnsVisible(
                this._creditCardOnlyPendingPaymentColumns,
                true
            );
            this._columnApi?.setColumnsVisible(
                this._echeckOnlyPendingPaymentColumns,
                false
            );
        } else if (
            this.pendingPaymentsDisplayForm.get("selectedPaymentType").value ===
            "ECHECK"
        ) {
            this._columnApi?.setColumnsVisible(
                this._creditCardOnlyPendingPaymentColumns,
                false
            );
            this._columnApi?.setColumnsVisible(
                this._echeckOnlyPendingPaymentColumns,
                true
            );
        }
    }

    private _setAccountSelectorsVisible(orgID: string) {
        this.showEcheckSelector =
            this.uiPaymentAccountTypesMap[orgID].hasEcheckAccount;
        this.showACHSelector =
            this.uiPaymentAccountTypesMap[orgID].hasACHAccount;
        this.showCreditCardSelector =
            this.uiPaymentAccountTypesMap[orgID].hasCreditCardAccount;
    }

    private _setupSelectorPendingPaymentCounts() {
        // get the pending payment counts from the data store
        const pendingPaymentsCounts: PendingPaymentsCountsObj =
            this._pendingPaymentsDataService.getPendingPaymentsCountObj();
        if (pendingPaymentsCounts) {
            let clonedOrgSelectorData = clone(this._masterOrgSelectorData);

            this._allOrgsIDArray.forEach((orgID: string) => {
                if (pendingPaymentsCounts[orgID]) {
                    const pendingPaymentCount: number =
                        pendingPaymentsCounts[orgID].total;
                    const rightMetadataClass: string =
                        pendingPaymentsCounts[orgID].anyDelinquentEcheck ||
                        pendingPaymentsCounts[orgID].anyDelinquentCreditCard ||
                        pendingPaymentsCounts[orgID].anyErrorsCreditCard
                            ? "badge-alert"
                            : "badge-required";
                    // find the orgSelectorDataObj for org id - find index
                    const orgIndex: number = clonedOrgSelectorData.findIndex(
                        (orgData: PendingPaymentsOrgFilterSelectionObject) =>
                            orgData.value === orgID
                    );
                    // add the metadata info using spread operator
                    clonedOrgSelectorData[orgIndex] = {
                        ...clonedOrgSelectorData[orgIndex],
                        pendingPaymentCount: pendingPaymentCount,
                        rightMetadataClass: rightMetadataClass
                    };
                }
            });
            this.orgSelectorData = clonedOrgSelectorData;

            // set echeck count/cc count based on current org selected. PS-10241. These will be public vars accessible by template
            const selectedOrgID =
                this.pendingPaymentsDisplayForm.get("selectedOrgOption").value;
            this.selectedOrgEcheckCount = pendingPaymentsCounts[selectedOrgID]
                ?.echeck
                ? pendingPaymentsCounts[selectedOrgID].echeck
                : null;
            this.selectedOrgCreditCardCount = pendingPaymentsCounts[
                selectedOrgID
            ]?.creditCard
                ? pendingPaymentsCounts[selectedOrgID].creditCard
                : null;
            this.selectedOrgEcheckCountClass = pendingPaymentsCounts[
                selectedOrgID
            ]?.anyDelinquentEcheck
                ? "badge-alert"
                : "badge-required";
            this.selectedOrgCreditCardClass =
                pendingPaymentsCounts[selectedOrgID]?.anyDelinquentCreditCard ||
                pendingPaymentsCounts[selectedOrgID]?.anyErrorsCreditCard
                    ? "badge-alert"
                    : "badge-required";
        } else {
            this.orgSelectorData = this._masterOrgSelectorData;
        }
    }

    private _finalizeGridSetup() {
        setTimeout(() => {
            this._showCorrectColumns();
            // setup counts for org selector/payments selector
            this._setupSelectorPendingPaymentCounts();
            if (this.selectedPaymentType === "ECHECK") {
                this._columnApi?.applyColumnState({
                    state: this._defaultEcheckSort
                });
            } else if (this.selectedPaymentType === "CREDIT_CARD") {
                this._columnApi?.applyColumnState({
                    state: this._defaultCreditCardSort
                });
            }
            this._gridApi.sizeColumnsToFit();
            //this._gridApi.hideOverlay();
            // if (this._pendingPaymentsLength === 0) {
            //     this._gridApi.showNoRowsOverlay();
            // }
            this.showTour();
            this.pendingPaymentCountsLoaded = true;
        }, 500);
    }

    private _switchRecordingPendingPayment(
        pendingPayment: any,
        paymentAccountID: string,
        isAccountTypeSwitched: boolean,
        switchedPaymentAccount: any
    ): Observable<boolean> {
        return this._pendingPaymentsNewService
            .setPackagePaymentAccounts(
                pendingPayment.packageID,
                paymentAccountID,
                pendingPayment.editableCategories
            )
            .pipe(
                tap(() => {
                    this._savePaymentAccount = false;
                    if (isAccountTypeSwitched) {
                        const paymentAccountSwitchedMessage: string =
                            this._formatPaymentAccountSwitchedMessage(
                                pendingPayment.label,
                                switchedPaymentAccount.type,
                                switchedPaymentAccount.label,
                                true
                            );
                        this._growlService.success(
                            `${paymentAccountSwitchedMessage}`,
                            null,
                            {
                                disableTimeOut: "timeOut",
                                extendedTimeOut: 1000,
                                enableHtml: true
                            }
                        );
                    }
                    this._growlService.success("Payment account saved");
                    this._refreshPendingPayments(true, false);
                    this._refreshPendingPaymentCount();
                    this._refreshBanner();
                }),
                catchError((err: any) => {
                    this._savePaymentAccount = false;
                    this._refreshPendingPayments(true, false);
                    let errMsg: string = err?.error?.errorMessage
                        ? err.error.errorMessage
                        : null;
                    if (errMsg)
                        errMsg =
                            "Unable to save new payment account - possible package issue: <br>" +
                            errMsg;
                    else
                        errMsg =
                            "Unable to save new payment account - possible package issue.";
                    this._growlService.error(errMsg);
                    return of(false);
                })
            );
    }

    private _switchLicensePendingPaymentToNotCreditCard(
        pendingPayment: any,
        paymentAccountID: string,
        oldPaymentAccountID: string,
        isAccountTypeSwitched: boolean,
        switchedPaymentAccount: any
    ) {
        const userID: string = this._sessionService.getUserID();
        return this._pendingPaymentsNewService
            .setLicenseAccount(
                pendingPayment.organizationID,
                pendingPayment.contractID,
                paymentAccountID,
                oldPaymentAccountID,
                pendingPayment.feeTotals.other,
                userID,
                pendingPayment.label
            )
            .pipe(
                tap(() => {
                    this._savePaymentAccount = false;
                    if (isAccountTypeSwitched) {
                        const paymentAccountSwitchedMessage: string =
                            this._formatPaymentAccountSwitchedMessage(
                                pendingPayment.label,
                                switchedPaymentAccount.type,
                                switchedPaymentAccount.label,
                                false
                            );
                        this._growlService.success(
                            `${paymentAccountSwitchedMessage}`,
                            null,
                            {
                                disableTimeOut: "timeOut",
                                extendedTimeOut: 1000,
                                enableHtml: true
                            }
                        );
                    }
                    this._growlService.success("Payment account saved");
                    this._refreshPendingPayments(true, false);
                    this._refreshPendingPaymentCount();
                    this._refreshBanner();
                }),
                catchError((err) => {
                    this._gridApi?.hideOverlay();
                    this._refreshPendingPayments(true, false);
                    this._refreshPendingPaymentCount();
                    this._refreshBanner();
                    return of(err.error.errorMessage);
                })
            );
    }

    private _switchBouncePayment(
        confirmSurchargeRes: ConfirmSurcharge
    ): Observable<boolean> {
        return this._submitterPaymentService
            .setBouncePaymentAccount(
                confirmSurchargeRes.pendingPayment.echeck.echeckID,
                confirmSurchargeRes.switchedPaymentAccount.id
            )
            .pipe(
                catchError((err: any) => {
                    this._gridApi?.hideOverlay();
                    return of(err.error.errorMessage);
                }),
                tap((errorMsg: any) => {
                    this._savePaymentAccount = false;
                    if (typeof errorMsg === "boolean") {
                        this._refreshPendingPayments(true, false);
                        this._refreshPendingPaymentCount();
                        this._refreshBanner();
                        this._growlService.success("Payment account saved");
                    } else if (typeof errorMsg === "string") {
                        this._growlService.error(
                            `Your $${confirmSurchargeRes.pendingPayment.feeTotals.total} payment account switch for ${confirmSurchargeRes.pendingPayment.label} (${confirmSurchargeRes.pendingPayment.organizationID}) with ${confirmSurchargeRes.switchedPaymentAccount.label} was unsuccessful. ${errorMsg}`,
                            null,
                            { disableTimeOut: true }
                        );
                        this._refreshPendingPayments(true, false);
                        this._refreshPendingPaymentCount();
                        this._refreshBanner();
                    }
                })
            );
    }

    private _switchLicensePendingPaymentToCreditCard(
        pendingPayment: any,
        pendingPaymentMethodType: "ECHECK" | "ACH" | "CREDIT_CARD",
        switchedPaymentAccount: any,
        paymentAccountID: string
    ): Observable<boolean> {
        this._retryPayment = true;
        let clonedPayment = clone(pendingPayment); // to send on resubmit
        switch (pendingPaymentMethodType) {
            case "ECHECK":
                delete clonedPayment.echeckLicenseTransactionItemID;
                delete clonedPayment.editMode;
                delete clonedPayment.isChild;
                delete clonedPayment.label;
                delete clonedPayment.status;
                clonedPayment.echeck = null;
                break;
            case "ACH":
                clonedPayment.failedTransactionItemID =
                    clonedPayment.achReferenceID;
                delete clonedPayment.achReferenceID;
                if (clonedPayment.items) {
                    delete clonedPayment.items.achReferenceID;
                    delete clonedPayment.items.contractID;
                    clonedPayment.items = [clonedPayment.items];
                } else {
                    clonedPayment.items = [
                        {
                            date: clonedPayment.date,
                            feeTotals: clonedPayment.feeTotals,
                            label: clonedPayment.label,
                            pendingPaymentType: clonedPayment.pendingPaymentType
                        }
                    ];
                }
                delete clonedPayment.date; // if this is a child item, then this field needs to be removed
                break;
            case "CREDIT_CARD":
                break;
            default:
                break;
        }
        clonedPayment.pendingPaymentMethodType = switchedPaymentAccount.type;
        clonedPayment.paymentAccountID = paymentAccountID;
        return this._submitterPaymentService
            .submitCreditCardTransactions(clonedPayment.organizationID, [
                clonedPayment
            ])
            .pipe(
                catchError((err: any) => {
                    this._gridApi?.hideOverlay();
                    this._refreshPendingPayments(true, false);
                    this._refreshPendingPaymentCount();
                    this._refreshBanner();
                    return of(err.error.errorMessage);
                }),
                tap((errorMessage: any) => {
                    this._savePaymentAccount = false;
                    this._retryPayment = false;
                    if (typeof errorMessage === "boolean") {
                        if (this._overlayPaymentType === "eCheck") {
                            this._overlayPaymentType = "credit card";
                        }
                        this._refreshPendingPayments(true, false);
                        this._refreshPendingPaymentCount();
                        this._refreshBanner();
                        this._growlService.success(
                            `Your $${pendingPayment.feeTotals.total} payment retry for ${pendingPayment.label} (${pendingPayment.organizationID}) was successful`
                        );
                    } else if (typeof errorMessage === "string") {
                        this._growlService.error(
                            `Your $${
                                pendingPayment.feeTotals.total
                            } payment retry for ${pendingPayment.label} (${
                                pendingPayment.organizationID
                            }) with ${
                                pendingPayment.orgPaymentAccounts.find(
                                    (card: any) => card.id === paymentAccountID
                                ).label
                            } was unsuccessful. ${errorMessage}`,
                            null,
                            { disableTimeOut: true }
                        );
                        this._refreshPendingPayments(true, false);
                    }
                    setTimeout(() => this._focusAway()); // set focus away
                })
            );
    }

    private _confirmSurcharge(
        pendingPayment: any,
        paymentAccountID: string,
        isAccountTypeSwitched: boolean,
        switchedPaymentAccount: any,
        orgID: string,
        feeAmount: string,
        addNewCC?: boolean
    ): Observable<ConfirmSurcharge | void> {
        let surchargePercent: number = 0;
        let surchargeAmount: number;
        let totalAmount: number;

        return this._userorgService.getOrganization(orgID).pipe(
            first(),
            switchMap((org: any) =>
                this._submitterFeeService.getCreditCardFeeSchedule(
                    org.address.state
                )
            ),
            tap((ccFeeSchedule: any) => {
                if (ccFeeSchedule.isEnabled) {
                    surchargePercent = +ccFeeSchedule.amount; // change to number
                }
                let amount: number = parseFloat(feeAmount);
                let surcharge: number = surchargePercent / 100;
                surchargeAmount = this._doTheRound(amount * surcharge, 2);
                totalAmount = amount + surchargeAmount;
            }),
            concatMap(() => {
                const modalRef = this._modalService.open(
                    ConfirmSurchargeDialogComponent,
                    {
                        backdrop: "static",
                        keyboard: false,
                        size: "xl"
                    }
                );

                const modalInstance = modalRef.componentInstance;

                modalInstance.ccAccountLabel = switchedPaymentAccount.label;
                modalInstance.chargedAmount = feeAmount;
                modalInstance.surchargePercent = surchargePercent;
                modalInstance.surchargeAmount = surchargeAmount;
                modalInstance.totalAmount = totalAmount;
                modalInstance.addNewCC = addNewCC;
                modalInstance.numSteps = addNewCC ? 2 : null;
                modalInstance.stepIndex = addNewCC ? 2 : null;

                return modalRef.result.then(
                    (surchargeConfirmed: any) => {
                        return {
                            pendingPayment: pendingPayment,
                            paymentAccountID: paymentAccountID,
                            isAccountTypeSwitched: isAccountTypeSwitched,
                            switchedPaymentAccount: switchedPaymentAccount,
                            surchargeConfirmed: surchargeConfirmed
                        };
                    },
                    () => {}
                );
            })
        );
    }

    private _addNewCardAddedSubscription(clonedPayment: any) {
        this.newCreditCardAdded$.subscribe(
            (res: any) => {
                this._retryPayment = false;
                if (this._overlayPaymentType === "eCheck") {
                    this._overlayPaymentType = "credit card";
                }
                this._refreshPendingPayments(true);
                this._refreshPendingPaymentCount();
                this._refreshBanner();
                if (typeof res === "boolean") {
                    this._growlService.success("Payment retry successful");
                } else {
                    const paymentAccountSwitchedToNewCardMessage: string =
                        this._formatPaymentAccountSwitchedMessage(
                            res.pendingPaymentLabel,
                            res.switchedAccountType,
                            res.switchedAccountLabel,
                            res.isRecording,
                            res.isNewCard,
                            res.hideLink,
                            res.isImmediateRetry
                        );
                    this._growlService.success(
                        `${paymentAccountSwitchedToNewCardMessage}`,
                        null,
                        {
                            disableTimeOut: "timeOut",
                            extendedTimeOut: 1000,
                            enableHtml: true
                        }
                    );
                    if (res.isNewCard && res.isFailedLicense) {
                        this._growlService.success(
                            "Default payment option for your license fee has been set to " +
                                res.switchedAccountLabel
                        );
                    }
                }
                setTimeout(() => this._focusAway()); // set focus away from CC account selector
            },
            (err: any) => {
                // Unsure if this is needed but just in case...
                this._retryPayment = false;
                this._refreshPendingPayments(true);
                if (
                    err.error.errorMessage ===
                    "Payment is not currently editable."
                ) {
                    this._growlService.error(
                        `Payment retry failed for type: ${clonedPayment.pendingPaymentType.toLowerCase()} - ${
                            clonedPayment.items[0].label
                        }. ${
                            err.error.errorMessage
                        } Please verify that the payment still needs to be retried.`,
                        null,
                        { disableTimeOut: true }
                    );
                } else if (
                    err.error.errorMessage === "Payment account not saved"
                ) {
                    this._growlService.error(
                        `${err.error.errorMessage} for package: ${clonedPayment.items[0].label}.`,
                        null,
                        { disableTimeOut: true }
                    );
                } else {
                    this._growlService.error(
                        `Payment retry failed for type: ${clonedPayment.pendingPaymentType.toLowerCase()} - ${
                            clonedPayment.items[0].label
                        }. ${err.error.errorMessage}`,
                        null,
                        { disableTimeOut: true }
                    );
                }
                setTimeout(() => this._focusAway()); // set focus away
            }
        );
    }

    private _getTotalWithoutSurcharge(feeTotals: any): string {
        let total: number = 0;
        total = total + (feeTotals.other ? parseFloat(feeTotals.other) : 0);
        total =
            total + (feeTotals.recording ? parseFloat(feeTotals.recording) : 0);
        total =
            total + (feeTotals.salesTax ? parseFloat(feeTotals.salesTax) : 0);
        total =
            total +
            (feeTotals.submission ? parseFloat(feeTotals.submission) : 0);
        total = total + (feeTotals.tax ? parseFloat(feeTotals.tax) : 0);
        total = total + (feeTotals.mail ? parseFloat(feeTotals.mail) : 0);
        return total + "";
    }

    private _doTheRound(num: number, scale: number): number {
        if (!("" + num).includes("e")) {
            return +(Math.round(Number(num + "e+" + scale)) + "e-" + scale);
        } else {
            let arr = ("" + num).split("e");
            let sig = "";
            if (+arr[1] + scale > 0) {
                sig = "+";
            }
            let i = +arr[0] + "e" + sig + (+arr[1] + scale);
            let j = Math.round(Number(i));
            let k = +(j + "e-" + scale);
            return k;
        }
    }
}
