import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BreakpointObserver } from "@angular/cdk/layout";
import { distinctUntilChanged, map, shareReplay } from "rxjs/operators";
import { WindowRefService } from "@sf/common";

@Injectable({
    providedIn: "root"
})
export class MobileViewService {
    private static readonly MOBILE_WIDTH_PX: number = 768;
    private static readonly WEBVIEW_RULES = [
        "WebView",
        // iOS webview will be the same as safari but missing "Safari"
        "(iPhone|iPod|iPad)(?!.*Safari)",
        // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
        // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
        "Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})",
        // old chrome android webview agent
        "Linux; U; Android"
    ];
    private static readonly WEBVIEW_REGEX = new RegExp(
        `(${MobileViewService.WEBVIEW_RULES.join("|")})`,
        "ig"
    );

    isMobileWidth$: Observable<boolean>;

    constructor(
        private _breakpointObserver: BreakpointObserver,
        private _windowRef: WindowRefService
    ) {
        this.isMobileWidth$ = this._breakpointObserver
            .observe([`(max-width: ${MobileViewService.MOBILE_WIDTH_PX}px)`])
            .pipe(
                map((state) => state.matches),
                distinctUntilChanged(),
                shareReplay(1)
            );
    }

    // Keep in sync with iframe-landing-page.html isMobile function
    isMobile() {
        // We tried using navigator.userAgentData.mobile when a user was using a Chromium
        // browser, but we've found tablet values to be inconsistent. Some tablets will
        // say they are mobile while others (like Galaxy Tab) will not.
        // Now we just rely on screen capture feature detection.
        const navigator: any = this._windowRef.nativeWindow.navigator;
        return !navigator.mediaDevices?.getDisplayMedia;
    }

    isIOS(): boolean {
        const userAgent: any = this._windowRef.nativeWindow.navigator.userAgent;
        return /iPad|iPhone|iPod/.test(userAgent);
    }

    isSafari(): boolean {
        const userAgent: any = this._windowRef.nativeWindow.navigator.userAgent;
        if (!/Safari/.test(userAgent)) {
            return false;
        } else {
            // Most browsers include Safari in their user agent strings on Apple products.
            return !/CriOS|Chrome|FxiOS|Firefox|Edg|OPR/.test(userAgent);
        }
    }

    // Is the site running in an embedded browser? This was copied from is-ua-webview package.
    isWebview(): boolean {
        const userAgent: any = this._windowRef.nativeWindow.navigator.userAgent;
        return !!userAgent.match(MobileViewService.WEBVIEW_REGEX);
    }

    isEccMobileApp() {
        return !!(this._windowRef.nativeWindow as any).webkit?.messageHandlers
            ?.iceMessageHandler;
    }
}
